import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Header from "../home/header";
import UTILS from "../../utils/commons";


const ProductDetail = (props)=>{
    let navigate = useNavigate();
    let location = useLocation();
    const storedData = location.state;
    const {id} = useParams();
    const p = storedData.products.find(p=>p.id === id);
    const loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    if(loginState){
        return(
            <>
            <Header auth={loginState}/>
            <section className="box">
                <div className="action-buttons"><span className="material-icons" onClick={()=>{navigate(-1,{replace:true,state:storedData})}}>close</span></div>
                <div className="medium-text">Product Details</div>
    <div className="content-full">
      <div className="logo">
        <img id="preview"  alt={p.name} src={UTILS.IMG_URL+((p.image) ? "/"+p.image+"?"+Date.now() : "img/no_data.svg")}/>
        <p className="medium-text" id="product_name"></p>
      </div>
      <div className="product-details">
       
        <div className="my-2">
            <label htmlFor="name" className="secondary">Product Name</label>
            <span
              id="name">{p.name}</span>
          </div>
          <div className="my-2">
            <label htmlFor="name">Product Description</label>
            <span
              id="description">{p.description}</span>
           
          </div>
          <div className="my-2">
              <label htmlFor="pack_size">Pack Size</label>
            <span
              id="pack_size">{p.pack_size}</span>
            </div>
          
      </div>
    </div>
    </section>
            </>
        )
    }
    
}
export default ProductDetail;