import React from "react";
import UTILS from "../../utils/commons";
const ReportHeadRow = props=>{

    return(<div className="col-lg-12 col-md-12 my-2 item-list-head bg-secondary">
        {props.data.map((d,i)=>{
            var className = (d.text.toLowerCase() == "date" || d.text.toLowerCase().includes("quantity") || d.text.toLowerCase().includes("action") || d.text.toLowerCase().includes("type")) ? " col-md-1 col-lg-1 col-sm-12 bold border border-primary" :" col-md-2 col-lg-2 col-sm-12 bold s";
            
            if(props.type ==='stock' || props.type ==="issues") className = "col-lg-2 col-md-2 col-sm-12 bold";
            if(d.text.toLowerCase().includes("supplier") ) className = "col-lg-2 col-md-2 col-sm-12 bold";
            if(props.type.includes("sales") && i<4) className="col-lg-1 col-md-1 col-sm-12 bold";
            else if(props.type.includes("transactions")) {
                className="col-lg-1 col-md-1 col-sm-12 bold";
                if(d.text.toLowerCase().includes("rep") || d.text.toLowerCase().includes("customer")) className = "col-lg-2 col-md-2 col-sm-12 bold";
            }
            else className="col-lg-2 col-md-2 col-sm-12 bold";
            return(<span key={UTILS.randomKey()} className={className+" "+d.align}>{d.text}</span>)
        })}
    </div>)
}
export default ReportHeadRow;