import React from "react";
import Header from "../home/header";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import Login from "../home/login_form";
import feature from "../../img/reps.svg";
import UTILS from "../../utils/commons";

const RepDetail = (props)=>{
    let navigate = useNavigate();
    let location = useLocation();
    var storedData = location.state;
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    var {id} = useParams();
    let data = storedData.reps.find(r=>r.id === id);

    if(loginState){
    return(<>
        <Header auth={loginState}/>
        <section className="box">
            <div className="action-buttons"><span className="material-icons" onClick={()=>{navigate(-1,{replace:true,state:storedData})}}>close</span></div>             
            <div className="medium-text">Sales Rep Details</div>
        <div className="content-full">
          <div className="logo">
            <img id="preview" src={data.avatar ? UTILS.IMG_URL+"/"+data.avatar+"?"+Date.now() : feature} alt={data.fname +" "+data.lname} />
            <p className="medium-text" id="rep_name">{data.fname + " "+data.lname}</p>
          </div>
          <div className="product-details">
           
            <div className="my-2">
                <label htmlFor="fname" >First Name</label>
                <span
                  id="fname">{data.fname}</span>
              </div>
              <div className="my-2">
                <label htmlFor="lname" >Last Name</label>
                <span
                  id="lname">{data.lname}</span>
              </div>
              <div className="my-2">
                <label htmlFor="area">Service Area</label>
                <span
                  id="area">{data.service_area}</span>
               
              </div>
              <div className="my-2">
                  <label htmlFor="phone">Phone</label>
                <span
                  id="phone">{data.phone}</span>
                </div>
                <div className="my-2">
                    <label htmlFor="email">E-mail</label>
                  <span
                    id="email">{data.email}</span>
                  </div>
                  <div className="my-2">
                   <a id="report_link">view report</a>
               </div>
              
          </div>
        </div>
        </section></>
    )
    }
    else return <Login target="/reps"/>
}
export default RepDetail;