import React from "react";
import { useLocation, useNavigate } from "react-router";

const Header = (props)=>{
  const session = window.sessionStorage;
  const storage = window.localStorage;

  let navigate = useNavigate();
    const signout=(e)=>{
        // e.preventDefault();
        if(window.confirm("Are you sure you want to sign out?")){
          session.clear();
          storage.clear();
          navigate("/");
        }
      }
    if(session.loginState){
      return(<header className="bg-curve-primary-up">
    <span className="material-icons dash" onClick={()=>{navigate('/dashboard',{replace:true})}}>apps</span>
    <nav>
      {props.auth ?<a className="nav-item" href="/settings" id="login">{props.auth.fname+" "+props.auth.lname}</a>:null}
      <a className="nav-item" href="/help">Help</a>
      <a className="nav-item" onClick={()=>signout()} >Sign out</a>
    </nav>
  </header>)
    }
    else{
      navigate('/signin');
    }
}
export default Header;