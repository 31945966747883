import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Header from "../home/header";
import Login from "../home/login_form";
import UTILS from '../../utils/commons';
import TxnItem from "./transaction_item";
import ReportHeadRow from "../reports/report_header";


const TransactionList=props=>{
    let navigate = useNavigate();
    var location = useLocation();
    const loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const storedData = JSON.parse(JSON.stringify(location.state));
    let payments = storedData.payments;
    let transactions = storedData.transactions;
    
    const [data,setData] = useState([]);
    const [filteredData,setFilteredData] = useState(data);
    var heads=[{text:"Date",align:"text-left"},{text:"Type",align:"text-left"},{text:"Invoice No.",align:"text-left"},{text:"Quantity (cartons)",align:"text-right"},{text:"Sales Rep",align:"text-left"},{text:"Customer",align:"text-left"},{text:"Product",align:"text-left"},{text:"Amount (Tsh.)",align:"text-right"},{text:"Paid (Tsh.)",align:"text-right"},{text:"Balance (Tsh.)",align:"text-right"}];
    
    const prepareData=()=>{
        var d;
        if(transactions.length > 0){
            d = transactions.map((is,k)=>{
                let mTx = JSON.parse(JSON.stringify(is));
                console.log('mTx: ',mTx)
                let paid = 0;
                mTx.type = parseInt(is.type) === 0 ? "Cash":"Credit";
                if(parseInt(is.type) === 0){
                    mTx.paid = is.cost;
                }
                else{
                    payments.forEach((i,m)=>{
                    if(i.reference === is.id ){
                        paid += parseInt(i.amount);
                    }
                    
                });
                mTx.paid = paid;
                }
                return mTx;
            })
        }
        else{
            d = []
        }
        
        setData(d);
        setFilteredData(d);
    }   
    const search=(e)=>{
        let keyword = e.target.value.trim().toLowerCase();
        let result = data;
        if(keyword.length > 0){
           result = data.filter(d=>d.type.toLowerCase().includes(keyword)||d.customer_detail.name.toLowerCase().includes(keyword)||d.product_detail.name.toLowerCase().includes(keyword) || d.rep.fname.toLowerCase().includes(keyword) || d.rep.lname.toLowerCase().includes(keyword)|| UTILS.getMonth(d.month).toLowerCase().includes(keyword));
        }   
        setFilteredData(result);
    }
    useEffect(()=>{
        prepareData(data);
    },[]);
    if(loginState){
        return(
            <>
            <Header auth={loginState}/>
            <section className="dashboard">
            <div className="container">
            <span className="actions-wide">
                <p className="large-text">Transactions</p>
                {/* <div className="row col-lg-10 col-md-10 offset-1 my-3"> */}
                        <input id="search" name="search" className="form-control-sm" placeholder="search" onChange={search}/>
                    {/* </div> */}
            </span>
            <div className="details-list">
                <ReportHeadRow data={heads} type="transactions"/>
                {filteredData.length > 0 ?
            filteredData.map(p=>{
                return <TxnItem data={p} key={UTILS.randomKey()} source={storedData}/>
            }):<span >No Data</span>}
            </div>
        </div>
            </section>
            </>
        )
    }
    else return <Login target="/transactions"/>
}
export default TransactionList;