import React from "react";
import { useLocation, useNavigate } from "react-router";
import UTILS from '../../utils/commons';


const InventoryReportItem = props => {
    const loginState = JSON.parse(window.sessionStorage.getItem('loginState'));
    var location = useLocation();
    var storedData = JSON.parse(JSON.stringify(location.state));
    var navigate = useNavigate();
    var d = props.data;

    var group;
    var sum = 0;
    var sumQ = 0;
    if (props.isGrouped && d.length > 0) {
        switch (props.group) {
            case "product":
                group = d[0].product.name + ' (' + d[0].product.pack_size + ")";
                break;
            case "invoice":
                group = "Invoice no: " + d[0].invoice_no;
                break;
            case "rep":
                group = `${d[0].rep.fname}  ${d[0].rep.lname}`;
                break;
            default:
                break;
        }
        d = d.map(x => {
            let k = x;
            k.date = UTILS.formatDate(parseInt(x.date_created) * 1000);
            k.quantity = parseInt(x.quantity);
            k.amount = parseInt(loginState.level) === UTILS.ADMIN ? parseInt(x.selling_price * x.quantity) : parseInt(x.price * x.quantity);
            return k;
        });

        sum = d.map(x => {
            x.amount = x.price * x.quantity;
            return x.amount;
        }).reduce((a, b) => a + b);
        sumQ = d.map(x => x.quantity).reduce((a, b) => a + b);
    }
    else {
        d.amount = parseInt(loginState.level) === UTILS.ADMIN ? parseInt(d.selling_price * d.quantity) : parseInt(d.price * d.quantity);
        d.date = UTILS.formatDate(parseInt(d.date_created) * 1000);
    }
    return (<>
        {props.isGrouped ? <div className="my-2 col-lg-12 col-md-12 item-list-head bg-success">
            <div className="px-2 col-lg-2 col-md-2 col-sm-12">{group}</div>
            <div className="px-2 col-lg-2 col-md-2 col-sm-12"></div>
            <div className="px-2 col-lg-2 col-md-2 col-sm-12 text-right">{UTILS.thousandSeparator(sumQ)}</div>
            <div className="px-2 col-lg-2 col-md-2 col-sm-12 text-right">{UTILS.thousandSeparator(sum)}</div>
            <div className="px-2 col-lg-2 col-md-2 col-sm-12 text-left"></div>
        </div> : null}
        {props.isGrouped ? d.map(d => {
            return (
                <div key={UTILS.randomKey()} className={"my-2 col-lg-12 col-md-12 item-list " + (props.pos % 2 === 0 ? "bg-light" : "")} >
                    <div className="col-md-2 col-lg-2 col-sm-12 bold text-left">{d.date}</div>
                    <div className="col-md-2 col-lg-2 col-sm-12 bold text-left text-primary" onClick={() => { navigate("/products/" + d.product.id, { state: storedData, replace: false }) }}>{d.product.name + " (" + d.product.pack_size + ")"}</div>
                    <div className="col-md-2 col-lg-2 col-sm-12 bold text-right">{UTILS.thousandSeparator(d.quantity)}</div>
                    <div className="col-md-2 col-lg-2 col-sm-12 bold text-right">{UTILS.thousandSeparator((parseInt(d.price)) * d.quantity)}</div>
                    <div className="col-md-2 col-lg-2 col-sm-12 bold text-left">
                        {d.invoice ? <a href={UTILS.IMG_URL + "/" + d.invoice} target="_blank" rel="noreferrer">{d.invoice_no}</a> : d.invoice_no}</div>
                    {/* {parseInt(loginState.level) === UTILS.ADMIN ?<div className="col-md-2 col-lg-2 col-sm-12 bold text-left text-primary" onClick={()=>{navigate('/suppliers/'+d.supplier.id,{state:storedData,replace:false})}}>{d.supplier.name}</div>:null} */}

                </div>)
        }) :
            <div className={"my-2 col-lg-12 col-md-12 item-list " + (props.pos % 2 === 0 ? "bg-light" : "")} >
                <div className="col-md-2 col-lg-2 col-sm-12 bold text-left">{d.date}</div>

                <div className="col-md-2 col-lg-2 col-sm-12 bold text-left text-primary" onClick={() => { navigate("/products/" + d.product.id, { state: storedData, replace: false }) }}>{d.product.name + " (" + d.product.pack_size + ")"}</div>
                <div className="col-md-2 col-lg-2 col-sm-12 bold text-right">{UTILS.thousandSeparator(d.quantity)}</div>
                <div className="col-md-2 col-lg-2 col-sm-12 bold text-right">{UTILS.thousandSeparator((parseInt(d.price)) * d.quantity)}</div>
                <div className="col-md-2 col-lg-2 col-sm-12 bold text-left">
                    {d.invoice ? <a href={UTILS.IMG_URL + "/" + d.invoice} target="_blank" rel="noreferrer">{d.invoice_no}</a> : d.invoice_no}</div>
                {parseInt(loginState.level) === UTILS.ADMIN && props.type === 'istock' ?<div className="col-md-2 col-lg-2 col-sm-12 bold text-left text-primary" onClick={()=>{navigate('/suppliers/'+d.supplier.id,{state:storedData,replace:false})}}>{d.supplier.name}</div>:null}

            </div>}</>)
}
export default InventoryReportItem;