import React from "react";
import Header from "../home/header";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import Login from "../home/login_form";
import feature from "../../img/customer.svg";
import UTILS from "../../utils/commons";

const CustomerDetail = (props)=>{
    let navigate = useNavigate();
    var location = useLocation();
    var storedData = JSON.parse(JSON.stringify(location.state));
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    var {id} = useParams();
    let data = storedData.customers.find(r=>r.id === id);
    console.log("p: ",storedData.customers,data);
    if(loginState){
    return(<>
        <Header auth={loginState}/>
        <section className="box">
            <div className="action-buttons"><span className="material-icons" onClick={()=>{navigate(-1,{replace:true,state:storedData})}}>close</span></div>
            <div><p className="medium-text">Customer Details</p></div>
        <div className="content-full">
          <div className="logo">
            <img id="preview" src={data.image ? UTILS.IMG_URL+"/"+data.image : feature} alt={data.name} />
            <p className="medium-text" id="customer_name">{data.name}</p>
          </div>
          <div className="product-details">
       
        <div className="my-2">
            <label htmlFor="name" >Customer Name</label>
            <span
              id="name">{data.name}</span>
          </div>
         
          <div className="my-2">
            <label htmlFor="address">Address</label>
            <span
              id="address">{data.address}</span>
           
          </div>
          <div className="my-2">
              <label htmlFor="phone">Phone</label>
            <span
              id="phone">{data.phone}</span>
            </div>
            <div className="my-2">
                <label htmlFor="email">E-mail</label>
              <span
                id="email">{data.email}</span>
              </div>
              {/* <div className="my-2">
               <a id="report_link">view report</a>
           </div> */}
          
    </div>
        </div>
        </section></>
    )
    }
    else return <Login target="/customers"/>
}
export default CustomerDetail;