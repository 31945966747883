import React from "react";
import {useNavigate } from "react-router";
import UTILS from '../../utils/commons';


const ReportTxnItem = props=>{
    var navigate = useNavigate();
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    var storedData = JSON.parse(JSON.stringify(props.source));
    var d = props.data;
    
    var group;
    var sum = 0;
    var sumQ = 0;
    if(props.isGrouped){
        switch(props.group){
            case "rep":
                group = d[0].rep.fname+ " "+d[0].rep.lname;
                break;
            case "product":
                group = d[0].product_detail.name+ ' ('+d[0].product_detail.pack_size+')';
                break;
            case "invoice":
                group = "Invoice no: "+d[0].invoice_no;
                break;
            default:
                break;
        }
        d = d.map(x=>{
            let k = x;
            k.date = UTILS.formatDate(x.date_created*1000);
            k.quantity = parseInt(x.quantity);
            k.cost = parseInt(x.cost);
            return k;
        });
        
        sum = d.map(x=>x.cost).reduce((a,b)=>a+b);
        sumQ= d.map(x=>x.quantity).reduce((a,b)=>a+b);
    }
    else{
        d.date = UTILS.formatDate(d.date_created*1000);
    
    }

    return(<>
    {props.isGrouped ? <div className=" my-2 col-lg-12 col-md-12 item-list bg-success">
        <div className="col-lg-3 col-md-3 col-sm-12">{group}</div>
        <div className="col-lg-1 col-md-1 col-sm-12 text-right">{UTILS.thousandSeparator(sumQ)}</div>
        <div className="col-lg-6 col-md-6 col-sm-12 text-left"></div>
        <div className="col-lg-2 col-md-2 col-sm-12 text-right">{UTILS.thousandSeparator(sum)}</div>
        
        </div>:null}
    {props.isGrouped ? d.map(x=>{
        return <div key={UTILS.randomKey()} className={" my-2 col-lg-12 col-md-12 item-list "+(props.pos%2===0 ? "bg-light":"")} >
            <div className="col-md-1 col-lg-1 col-sm-12 bold text-left">{x.date}</div>
            <div className="col-md-1 col-lg-1 col-sm-12 bold text-left">{x.file ? <a href={UTILS.IMG_URL+"/"+x.file} target="_blank" rel="noreferrer">{parseInt(x.type) === 1?'Credit':'Cash'}</a>:parseInt(x.type) === 1?'Credit':'Cash'}</div>
            <div className="col-md-1 col-lg-1 col-sm-12 bold text-left">
                {x.file ? <a href={UTILS.IMG_URL+"/"+x.invoice} target="_blank" rel="noreferrer">{x.invoice_no}</a>:x.invoice_no}
            </div>
            <div className="col-md-1 col-lg-1 col-sm-12 bold text-right">{x.quantity}</div>
            {parseInt(loginState.level) === UTILS.ADMIN ?<div className="col-md-2 col-lg-2 col-sm-12 bold text-left text-primary" onClick={()=>{navigate('/reps/'+x.rep.id,{replace:false,state:storedData})}}>{x.rep.fname+" "+x.rep.lname}</div>:
            <div className="col-md-2 col-lg-2 col-sm-12 bold text-left" >{x.rep.fname+" "+x.rep.lname}</div>
        }
            <div className="col-md-2 col-lg-2 col-sm-12 bold text-left text-primary" onClick={()=>{navigate('/customers/'+x.customer_detail.id,{replace:false,state:storedData})}}>{x.customer_detail.name}</div>
            <div className="col-md-2 col-lg-2 col-sm-12 bold text-left text-primary" onClick={()=>{navigate("/products/"+x.product_detail.id,{replace:false,state:storedData})}}>{x.product_detail.name+" ("+x.product_detail.pack_size+")"}</div>
            <div className="col-md-2 col-lg-2 col-sm-12 bold text-right">{UTILS.thousandSeparator(x.cost)}</div>
            </div>
    }):
    <div className={" my-2 col-lg-12 col-md-12 item-list "+(props.pos%2===0 ? "bg-light":"")}>
        <div className="col-md-1 col-lg-1 col-sm-12 bold text-left">{d.date}</div>
        <div className="col-md-1 col-lg-1 col-sm-12 bold text-left text-primary" onClick={()=>{navigate("/transactions/"+d.id,{replace:false,state:storedData})}}>{parseInt(d.type) === 1?'Credit':'Cash'}</div>
        <div className="col-md-1 col-lg-1 col-sm-12 bold text-left">
        {parseInt(loginState.level)=== UTILS.ADMIN && d.file ? <a href={UTILS.IMG_URL+"/"+d.invoice} target="_blank" rel="noreferrer">{d.invoice_no}</a>:d.invoice_no}
        </div>
        <div className="col-md-1 col-lg-1 col-sm-12 bold text-right">{d.quantity}</div>
            {parseInt(loginState.level) === UTILS.ADMIN ?<div className="col-md-2 col-lg-2 col-sm-12 bold text-left text-primary" onClick={()=>{navigate('/reps/'+d.rep.id,{replace:false,state:storedData})}}>{d.rep.fname+" "+d.rep.lname}</div>:
            <div className="col-md-2 col-lg-2 col-sm-12 bold text-left" >{d.rep.fname+" "+d.rep.lname}</div>
        }<div className="col-md-2 col-lg-2 col-sm-12 bold text-left text-primary" onClick={()=>{navigate('/customers/'+d.customer_detail.id,{replace:false,state:storedData})}}>{d.customer_detail.name}</div>
        <div className="col-md-2 col-lg-2 col-sm-12 bold text-left text-primary" onClick={()=>{navigate("/products/"+d.product_detail.id,{replace:false,state:storedData})}}>{d.product_detail.name+" ("+d.product_detail.pack_size+")"}</div>
        
        <div className="col-md-2 col-lg-2 col-sm-12 bold text-right">{UTILS.thousandSeparator(d.cost)}</div>
    </div>}</>)
}
export default ReportTxnItem;