import React from "react";
import Header from "../home/header";
import feature from "../../img/suppliers.svg";
import { useLocation, useNavigate, useParams } from "react-router";
import Login from "../home/login_form";

const SupplierDetail = (props)=>{
    let navigate = useNavigate();
    var location = useLocation();
    const loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const storedData = location.state;
    var {id} = useParams();
    var s = storedData.suppliers.find(s=>s.id === id);

    if(loginState){
    return(<>
    <Header auth={loginState}/>
    <section className="box">
        <div className="action-buttons"><span className="material-icons" onClick={()=>{navigate(-1,{replace:true,state:storedData})}}>close</span></div>
        <div className="medium-text">Supplier Details</div>
    <div className="content-full">
      <div className="logo">
        <img id="preview" src={feature} alt="Supplier" />
        <p className="medium-text" id="rep_name">{s.name}</p>
      </div>
      <div className="product-details">
       
        <div className="my-2">
            <label htmlFor="name" >Company Name</label>
            <span
              id="name">{s.name}</span>
          </div>
          <div className="my-2">
            <label htmlFor="country">Country</label>
            <span
              id="country">{s.country}</span>
          </div>
          <div className="my-2">
            <label htmlFor="address">Address</label>
            <span
              id="address">{s.address}</span>
           
          </div>
          <div className="my-2">
            <label htmlFor="contact">Contact Person</label>
            <span
              id="contact">{s.contact_person}</span>
           
          </div>
          <div className="my-2">
              <label htmlFor="phone">Phone</label>
            <span
              id="phone">{s.phone}</span>
            </div>
            <div className="my-2">
                <label htmlFor="email">E-mail</label>
              <span
                id="email">{s.email}</span>
              </div>
              <div className="my-2">
               <a id="report_link">view reports</a>
           </div>
          
      </div>
    </div>
    </section>
    </>)
    }
    else return <Login target="/suppliers"/>
}
export default SupplierDetail;