import React from "react";
import UTILS from "../../utils/commons";

const ProductItem = (props)=>{
    var p = props.data;   
    return(
        <div className="item-list">
            <img className="item-image" alt={p.name} src={((p.image) ? UTILS.IMG_URL+"/"+p.image+"?"+Date.now() : "img/no_data.svg")}  onClick={(id)=>props.onClick(id)}/>
            <div className="item-data"  onClick={(id)=>props.onClick(id)}>
                <span className="item-title">{p.name}</span>
                <span className="item-focus">{p.pack_size}</span>
            </div>
            <div className="action-buttons">
                <span className="material-icons" onClick={(id)=>props.onEdit(id)}>edit</span>
                <span className="material-icons" onClick={(id)=>props.onDelete(id)}>delete</span>
            </div>
        </div>
    )
}
export default ProductItem;