import React, { useState } from "react";
import Header from "../home/header";
import UTILS from "../../utils/commons"
import feature from "../../img/products.svg";
import { useLocation, useNavigate } from "react-router";
import Loading from "../home/Loading";

const ProductForm=(props)=>{
    let navigate = useNavigate();
    let location = useLocation();
    const loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const storedData = location.state;
    const [feedback,setFeedback] = useState("");
    const [isLoading,setIsLoading] = useState(false);

//save product
const saveProduct = (product)=>{
    var formData = new FormData();
    formData.append("name",product.name);
    formData.append("description",product.description);
    formData.append("pack_size",product.pack_size);
    formData.append("user_id",product.user_id);
    formData.append("image",product.image);
    formData.append("btnAddProduct","addProduct");
    // console.log("formdata: ",JSON.stringify(formData));
    var options = {
        method:"POST",
        body:formData,
        
    }
    fetch(UTILS.URL +"?tag=product",options)
    .then(res=>res.json())
    .then(result=>{
        console.log("result: ",result);        
        if(parseInt(result.code) === 0){
          var d = storedData;
            d.products = result.products;
            
            navigate("/products",{replace:true,state:d});
        }
        else setFeedback(result.msg);
        setIsLoading(false);
    })
    .catch(err=>{
        console.log("err: ",err);
        setFeedback("Oops! Something went wrong");
        setIsLoading(false);
    }).finally(()=>{
      setIsLoading(false);
    })
}
const handleSubmit=(event)=>{
    event.preventDefault();
    setIsLoading(true);
    let name = document.getElementById("name").value;
    let description = document.getElementById("description").value;
    let pack_size = document.getElementById("pack_size").value;
    let file = document.getElementById("image_file").files[0];

    var userId = loginState.id;
    let prod = {name:name,description:description,pack_size:pack_size,image:file,user_id:userId};
    saveProduct(prod);
}
    if(loginState){
    return(
        <>
        <Header auth={loginState}/>
        <section className="dashboard">
        {feedback.length > 0 ?<div className="feedback col-md-8 col-lg-8 col-sm-10 py-2 danger" id="feedback">{feedback}</div>:null}
               
    <div className="content-full">
      <div className="logo">
        <img src={feature} alt="Sales rep feature" />
        <p className="medium-text">Add New Product</p>
      </div>
      <div className="col-md-4 col-lg-4 col-sm-10">
        <form id="new_product_form"
          className="col-md-8 col-lg-8 col-sm-10"
          method="post" onSubmit={handleSubmit}
        >
        <div className="form-group my-2">
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Product name"
            />
          </div>
          <div className="form-group my-2">
            <input
              type="text"
              className="form-control"
              id="description"
              name="description"
              placeholder="Product Description"
            />
          </div>
          <div className="form-group my-2">
              <label htmlFor="pack_size">Select Pack Size</label>
            <select
              className="form-control"
              id="pack_size"
              name="pack_size">
              <option>100ML</option>
              <option>200ML</option>
              <option>250ML</option>
              <option>500ML</option>
              <option>1LT</option>
              <option>200G</option>
              <option>500G</option>
              <option>1KG</option>
            </select>
            </div>
            <div className="form-group my-2">
               
              <input
              type="file"
                className="form-control"
                id="image_file"
                name="image_file"/>
               
              </div>
         <div className="form-group my-2 row">
          <div className="my-2 col-md-6 col-lg-6 col-sm-10">
            {isLoading ? <Loading />:
            <input
              type="submit"
              className="form-control bg-success"
              id="btnSubmit"
              name="btnSubmit"
              value="SUBMIT"
            />}
          </div>
          <div className="my-2 col-md-6 col-lg-6 col-sm-10">
            <input onClick={()=>{navigate(-1)}}
              type="button"
              className="form-control"
              value="Cancel"
            />
          </div>
        </div>
        </form>
      </div>
    </div>
    </section>
        </>
    )
    }
}
export default ProductForm;