import React from "react";
import UTILS from "../../utils/commons";
import avatar from "../../img/no_data.svg";

const CustomerItem = (props)=>{

    var p = props.data;       
    return(
        <div className="item-list">
            <img className="item-image" alt={p.name} src={((p.image) ? UTILS.IMG_URL+"/"+p.image+"?"+Date.now() : avatar)}  onClick={(id)=>props.onClick(id)}/>
            <div className="item-data"  onClick={(id)=>props.onClick(id)}>
                <span className="item-title">{p.name}</span>
                <span className="item-focus">{p.phone+", "+p.address}</span>
            </div>
            <div className="action-buttons">
                <span className="material-icons" onClick={(id)=>props.onEdit(id)}>edit</span>
                <span className="material-icons" onClick={(id)=>props.onDelete(id)}>delete</span>
            </div>
        </div>
    )
}
export default CustomerItem;