import React from "react";
import Header from "../home/header";
import repImage from '../../img/customer.svg'; 
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import Login from "../home/login_form";
import UTILS from "../../utils/commons"
import Loading from "../home/Loading";

const CustomerForm = ()=>{
let navigate = useNavigate();
var location = useLocation();
const loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
var storedData = JSON.parse(JSON.stringify(location.state));
const [isLoading,setIsLoading] = useState(false);
const [feedback,setFeedback] = useState("");

const handleSubmit = (e)=>{
  e.preventDefault();
  let name = document.getElementById("name").value;
    let address = document.getElementById("address").value;
    let phone = document.getElementById("phone").value;
    let email = document.getElementById("email").value;            
    let user_id = loginState.id;
    let file = document.getElementById("customer_image").files[0];
    let customer = {name:name,address:address,email:email,phone:phone,rep:user_id,image:file};
    saveCustomer(customer);
}
const uploadImage=(e)=>{
  e.preventDefault();
  const previewImage = document.getElementById("rep_preview");
  let file = e.target.files[0];
  if(file){
    //  data.image = file;
      let reader = new FileReader();
      reader.addEventListener('load',()=>{
          const url = URL.createObjectURL(file);
          previewImage.src = url;
          // saveProduct(prod);
      },false);
      reader.readAsDataURL(file);
  }
           
} 
const saveCustomer=(customer)=>{
    setIsLoading(true);
    var formData = new FormData();
    formData.append("name",customer.name);
    formData.append("address",customer.address);
    formData.append("email",customer.email);
    formData.append("phone",customer.phone);
    formData.append("rep",customer.rep);
    formData.append("image",customer.image);
    formData.append("btnAddCustomer","addCustomer");
    fetch(UTILS.URL+"?tag=customer",{method:"POST",body:formData,cache:'reload'})
    .then(res=>res.json())
    .then(result=>{
        if(result.code === 0){
            storedData.customers = result.customers;
            navigate("/customers",{replace:true,state:storedData})
        }
        else{
            setFeedback(result.msg);
        }
    })
    .catch(e=>{
        setFeedback('Oops! Something went wrong');
    })
    .finally(()=>{
      setIsLoading(false);
    })
}
if(loginState){
    return(<>
    <Header auth={loginState}/>
    <section className="dashboard"> {feedback && feedback.length > 0 ?<div className="feedback col-md-8 col-lg-8 col-sm-10 py-2 danger" id="feedback">{feedback}</div>:null}
          
    <div className="content-full">
      <div className="logo">
        <img id="rep_preview" src={repImage} alt="Sales rep feature" />
        <p className="medium-text">Add Customer</p>
      </div>
      <div className="col-md-4 col-lg-4 col-sm-10">
        <form id="new_customer_form"
          className="col-md-8 col-lg-8 col-sm-10"
          method="post" onSubmit={handleSubmit}
        >
        <div className="form-group my-2">
            <input
              type="text"
              className="form-control"
              id="name"
              name="name" required
              placeholder="Customer name"
            />
          </div>
          <div className="form-group my-2">
            <input
              type="file"
              className="form-control"
              id="customer_image"
              name="customer_image"
              placeholder="Upload customer image" onChange={uploadImage}
            />
          </div>
          <div className="form-group my-2">
            <input
              type="text"
              className="form-control"
              id="address"
              name="address" required
              placeholder="Address"
            />
          </div>
          <div className="form-group my-2">
            <input
              type="number"
              className="form-control"
              id="phone"
              name="phone" required
              placeholder="Phone Number"
            />
          </div>
          <div className="form-group my-2">
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="E-mail"
            />
          </div>

         <div className="form-group my-2 row">
          <div className="my-2 col-md-6 col-lg-6 col-sm-10">
            {isLoading ? <Loading />:
            <input
              type="submit"
              className="form-control bg-success"
              id="btnSubmit"
              name="btnSubmit"
              value="SUBMIT"
            />}
          </div>
          <div className="my-2 col-md-6 col-lg-6 col-sm-10">
            <input onClick={()=>{navigate("/customers",{replace:true,state:storedData})}}
              type="button"
              className="form-control"
              value="Cancel"
            />
          </div>
        </div>
        </form>
      </div>
    </div>
    </section></>)
}
else return <Login target="/customers"/>
}
export default CustomerForm;