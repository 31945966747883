import React,{useState} from "react";
import { useNavigate } from "react-router";
import logo from "../../logo.png"
import UTILS from "../../utils/commons";
import Loading from "./Loading";
const Login=(props)=>{
const [isLoading,setIsLoading] = useState(false);
const [feedback,setFeeback] = useState("");
const session = window.sessionStorage;
var navigate = useNavigate();

const sendLogin=(loginForm)=>{
return new Promise((resolve,reject)=>{
  let inputs = Array.from(loginForm.elements);
        let email = inputs[0].value.trim();
        let pword = inputs[1].value.trim();
        let user = {email:email,password:pword,btnLogin:"login"};
        var options = {
            body:JSON.stringify(user),
            method:"POST",headers:{'Content-type':'application/json'}
        }
        
        fetch(UTILS.URL,options)
        .then(res=>res.json())
        .then(result=>{
          resolve(result);
           
        })
        .catch(e=>{
            console.log(e);
            reject("Oops! Something went wrong");
          
        })
})
}
const handleSubmit=(e)=>{
  e.preventDefault();
  setIsLoading(true);
  sendLogin(e.target)
  .then(result=>{
    setIsLoading(false);
    console.log("test; ",result);
    if(result.code == 1){
      setFeeback(result.msg);
    }
    else{
        let user = result.user;
        session.setItem("loginState",JSON.stringify(user));
        navigate(props.target ? props.target:"/dashboard",{replace:true});
    }
    setIsLoading(false);
  })
  .catch(e=>{
    setFeeback(e);
    setIsLoading(false);
  })
}
 return(
       
            <section className="content-full">
              <div className="logo">
                <img src={logo} alt="Rainbow logo" />
                <p className="medium-text">Inventory and Distribution Management System</p>
              </div>
              <div className="row primary-bg">
                {feedback.length > 0 ?<div className="feedback col-md-8 col-lg-8 col-sm-10 py-2" id="feedback">{feedback}</div>:null}
               
                <form id="loginform"
                  className="col-md-6 col-lg-6 col-sm-10"
                  method="post" onSubmit={handleSubmit}
                >
                  
                  <div className="form-group my-2">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      required
                      placeholder="E-mail"
                    />
                  </div>
        
                  <div className="form-group my-2">
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      placeholder="Password"
                    />
                  </div>
                  <div className="form-group my-2">
                    <a className="link-button" href="/reset">Forgot password</a>
                  </div>
                  <div className="form-group my-2">
                    {isLoading ? <Loading />:
                    <input
                      type="submit"
                      className="form-control bg-success"
                      id="btnlogin"
                      name="btnlogin"
                      value="Sign In"
                    />}
                  </div>
                  <div className="form-group my-2">
                    {/* <span>Not registered? &nbsp;<a className="link-button" href="/signup">Register now</a></span> */}
                  </div>
                </form>
              </div>
            </section>
            
        
    );
}
export default Login;