import React from "react";
import Header from "../home/header";
import UTILS from "../../utils/commons"
import feature from "../../img/products.svg";
import { useLocation, useNavigate, useParams } from "react-router";
import { useState } from "react";
import Loading from "../home/Loading";

const ProductEditForm=(props)=>{
    let navigate = useNavigate();
    var location = useLocation();
    const loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const storedData = location.state;//JSON.parse(window.localStorage.getItem("db"));

    const {id} = useParams();
    let prod = storedData.products.find(p=>p.id === id);
    const [data,setData] = useState(prod);
    const [feedback,setFeedback] = useState("");
    const [isLoading,setIsLoading] = useState(false);

//save product
const saveProduct = (product)=>{
  console.log("pd: ",product);
    var formData = new FormData();
    formData.append("name",product.name);
    formData.append("description",product.description);
    formData.append("pack_size",product.pack_size);
    formData.append("user_id",product.user_id);
    formData.append("image",product.image);
    formData.append("id",prod.id);
    formData.append("status",prod.status);
    formData.append("btnEditProduct","edit");
    var options = {
        method:"POST",
        body:formData,
        cache:'reload'
        
    }
    fetch(UTILS.URL +"?tag=product",options)
    .then(res=>res.json())
    .then(result=>{
      console.log("result: ",result);
        if(parseInt(result.code) === 0){
          var d = storedData;
            d.products = result.products;
            
            navigate("/products",{replace:true,state:d});
        }
        else setFeedback(result.msg);
        setIsLoading(false);
    })
    .catch(err=>{
        console.log("err: ",err);
        setFeedback("Oops! Something went wrong");
        setIsLoading(false);
    })
}
const handleSubmit=(event)=>{
    event.preventDefault();
    setIsLoading(true);
    let name = document.getElementById("name").value;
    let description = document.getElementById("description").value;
    let pack_size = document.getElementById("pack_size").value;
    let file = document.getElementById("image_file").files[0];

    var userId = loginState.id;
    let prod = {name:name,description:description,pack_size:pack_size,user_id:userId};
    if(file) prod.image = file;
    saveProduct(prod);
}
const handleChange=(e)=>{
    let target = e.target;
    let value = target.value;
    var newData = data;
    switch(target.id){
        case "name":
            newData.name = value;
            break;
        case "description":
            newData.description = value;
            break;
        case "pack_size":
            newData.pack_size = value;
            break;
        default:
            break;
    }
    setData(newData);
}
const uploadImage=(e)=>{
  e.preventDefault();
  const previewImage = document.getElementById("preview");
  let file = e.target.files[0];
  if(file){
     data.image = file;
      let reader = new FileReader();
      reader.addEventListener('load',()=>{
          const url = URL.createObjectURL(file);
          previewImage.src = url;
          // saveProduct(prod);
      },false);
      reader.readAsDataURL(file);
  }
           
}      

if(loginState){
    return(
        <>
        <Header auth={loginState}/>
        <section className="dashboard">
          {feedback && feedback.length > 0 ?<div className="feedback col-md-8 col-lg-8 col-sm-10 py-2 danger" id="feedback">{feedback}</div>:null}
         
    <div className="content-full">
      <div className="logo">
        <img src={data.image ? UTILS.IMG_URL+"/"+data.image:feature} alt="Sales product feature" id="preview"/>
        <p className="medium-text">Edit Product</p>
      </div>
      <div className="col-md-4 col-lg-4 col-sm-10">
        <form id="new_product_form"
          className="col-md-8 col-lg-8 col-sm-10"
          method="post" onSubmit={handleSubmit}
        >
        <div className="form-group my-2">
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Product name"
              defaultValue={data.name} onChange={(e)=>handleChange(e)}
            />
          </div>
          <div className="form-group my-2">
            <input
              type="text"
              className="form-control"
              id="description"
              name="description"
              placeholder="Product Description"
              defaultValue={data.description} onChange={(e)=>handleChange(e)}
            />
          </div>
          <div className="form-group my-2">
              <label htmlFor="pack_size">Select Pack Size</label>
            <select
              className="form-control"
              id="pack_size"
              name="pack_size" 
              defaultValue={data.pack_size} onChange={(e)=>handleChange(e)}>
              <option>100ML</option>
              <option>200ML</option>
              <option>250ML</option>
              <option>500ML</option>
              <option>1LT</option>
              <option>200G</option>
              <option>500G</option>
              <option>1KG</option>
            </select>
            </div>
            <div className="form-group my-2">
               
              <input onChange={(e)=>uploadImage(e)}
              type="file"
                className="form-control"
                id="image_file"
                name="image_file"/>
               
              </div>
         <div className="form-group my-2 row">
          <div className="my-2 col-md-6 col-lg-6 col-sm-10">
            {isLoading ? <Loading />:
            <input
              type="submit"
              className="form-control bg-success"
              id="btnSubmit"
              name="btnSubmit"
              value="SUBMIT"
            />}
          </div>
          <div className="my-2 col-md-6 col-lg-6 col-sm-10">
            <input onClick={()=>{navigate("/products",{replace:true,state:storedData})}}
              type="button"
              className="form-control"
              value="Cancel"
            />
          </div>
        </div>
        </form>
      </div>
    </div>
    </section>
        </>
    )
    }
}
export default ProductEditForm;