import React from "react";
import {useNavigate } from "react-router";
import UTILS from '../../utils/commons';


const TxnItem = props=>{
    var navigate = useNavigate();
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    var storedData = JSON.parse(JSON.stringify(props.source));
    var d = props.data;
    d.date = UTILS.formatDate(d.date_created*1000);
   
    return(
    
    <div className={"col-lg-12 col-md-12 item-list "+(props.pos%2===0 ? "bg-light":"")+((d.cost > d.paid) ? " flag-red":" flag-ok")} onClick={()=>{navigate("/transactions/"+d.id,{replace:false,state:storedData})}}>
        <div className="col-md-1 col-lg-1 col-sm-12 bold text-left">{d.date}</div>
        
        <div className="col-md-1 col-lg-1 col-sm-12 bold text-left">
            {d.file ? <a href={UTILS.IMG_URL+"/"+d.file} target="_blank" rel="noreferrer">{d.type}</a>:d.type}
        </div>
        <div className="col-md-1 col-lg-1 col-sm-12 bold text-left">
        {d.invoice ? <a href={UTILS.IMG_URL+"/"+d.invoice} target="_blank" rel="noreferrer">{d.invoice_no}</a>:d.invoice_no}
        </div>
        <div className="col-md-1 col-lg-1 col-sm-12 bold text-right">{d.quantity}</div>
        {parseInt(loginState.level) === UTILS.ADMIN ? <div className="col-md-2 col-lg-2 col-sm-12 bold text-left text-primary clickable" onClick={(e)=>{e.stopPropagation();navigate('/reps/'+d.rep.id,{replace:false,state:storedData})}}>{d.rep.fname+" "+d.rep.lname}</div>:
        <div className="col-md-2 col-lg-2 col-sm-12 bold text-left">{d.rep.fname+" "+d.rep.lname}</div>}
        <div className="col-md-2 col-lg-2 col-sm-12 bold text-left text-primary clickable" onClick={(e)=>{e.stopPropagation();navigate('/customers/'+d.customer_detail.id,{replace:false,state:storedData})}}>{d.customer_detail.name}</div>
        <div className="col-md-2 col-lg-1 col-sm-12 bold text-left text-primary clickable" onClick={(e)=>{e.stopPropagation();navigate("/products/"+d.product_detail.id,{replace:false,state:storedData})}}>{d.product_detail.name}</div>
        
        <div className="col-md-1 col-lg-1 col-sm-12 bold text-right">{UTILS.thousandSeparator(d.cost)}</div>
        <div className="col-md-1 col-lg-1 col-sm-12 bold text-right">{UTILS.thousandSeparator(d.paid)}</div>
        <div className="col-md-1 col-lg-1 col-sm-12 bold text-right">{UTILS.thousandSeparator(d.cost - d.paid)}</div>
    </div>)
}
export default TxnItem;