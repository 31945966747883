import React from "react";
import UTILS from "../../utils/commons";
import avatar from "../../img/no_data.svg";

const RepItem = (props)=>{

    var p = props.data;       
    return(
        <div className="item-list">
            <img className="item-image" alt={p.fname} src={((p.avatar) ? UTILS.IMG_URL+"/"+p.avatar+"?"+Date.now() : avatar)}  onClick={(id)=>props.onClick(id)}/>
            <div className="item-data"  onClick={(id)=>props.onClick(id)}>
                <span className="item-title">{p.fname+" "+p.lname}</span>
                <span className="item-focus">{p.service_area}</span>
            </div>
            <div className="action-buttons">
                <span className="material-icons" onClick={(id)=>props.onEdit(id)}>edit</span>
                <span className="material-icons" onClick={(id)=>props.onDelete(id)}>delete</span>
            </div>
        </div>
    )
}
export default RepItem;