import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useState } from "react";
import Header from "../home/header";
import PaymentItem from "./payment_item";
import commons from "../../utils/commons";
import ReportHeadRow from "../reports/report_header";

const Payments=props=>{
    var location = useLocation();
    var navigate = useNavigate();
    var storedData = JSON.parse(JSON.stringify(location.state));
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    
    const [payments,setPayments] = useState([]);
    const [filteredData,setFilteredData] = useState(payments);
    var heads=[{text:"Date",align:"text-left"},{text:"Account",align:"text-left"},{text:"Reference",align:"text-left"},{text:"Amount (Tsh)",align:"text-right"},{text:"Proof of payment",align:"text-left"},{text:"Recorded by",align:"text-left"}];
       
    const search=(e)=>{
        let keyword = e.target.value.trim().toLowerCase();
        let result = payments;
        if(keyword.length > 0){
            result = payments.filter(d=>d.account.name.toLowerCase().includes(keyword) || d.date.toLowerCase().includes(keyword) || d.owner.fname.toLowerCase().includes(keyword) || d.owner.lname.toLowerCase().includes(keyword));
        }   
        setFilteredData(result);
    }
    useEffect(()=>{
        setPayments(storedData.payments);
        setFilteredData(storedData.payments);
    },[])
    if(loginState){
        return(<>
        <Header auth={loginState}/>
        <section className="dashboard">
        <div className="container">
            <span className="actions-wide">
                <p className="large-text">Payments</p>
                <button className="btn bg-success" onClick={()=>navigate("/payments/new",{replace:false,state:storedData})}>Add</button>
            </span>
            <input className="form-control" placeholder="search" type="text" onChange={search} />
            <div className="details-list">
                <ReportHeadRow data={heads} type="payments"/>
                {filteredData.length > 0 ?
            filteredData.map(p=>{
                return <PaymentItem data={p} key={commons.randomKey()} source={storedData}/>
            }):<span>No data</span>}
            </div>
        </div>
      </section>
        </>)
    }

}
export default Payments;