import React from "react";
import Header from "../home/header";
import {useLocation, useNavigate} from 'react-router-dom';
import Login from "../home/login_form";
import { useEffect,useState } from "react";
import CustomerItem from "../customers/customer_item";
import UTILS from '../../utils/commons';

const Customers=(props)=>{
    let navigate = useNavigate();
    var location = useLocation();
    var storedData = JSON.parse(JSON.stringify(location.state));
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));

    const [customers,setCustomers] = useState([]);
    const [feedback,setFeedback] = useState("");
    var data = storedData.customers.filter(p=>parseInt(p.status)===0);
    const getCustomers=()=>{
        setCustomers(data);
    }
    useEffect(()=>{
        getCustomers(customers);
    },[]);
    const confirmDelete = (itemId)=>{
        let message = "Are you sure you want to delete this customer?";
        if(window.confirm(message)){
        
            deleteCustomer(itemId).then(result=>{
                if(parseInt(result.code) === 0){
                    storedData.customers = result.customers;
                    setCustomers(storedData.customers.filter(p=>parseInt(p.status) === 0));
                }
                else setFeedback(result.msg);
                
            }).catch(e=>{
                console.log("error: ",e);
                setFeedback("Oops! Something went wrong");
            })
        }
        
    }
    const deleteCustomer=(pid)=>{
        return new Promise((resolve,reject)=>{
            let url = UTILS.URL +"?tag=customer";
            var formData = new FormData();
            formData.append("rep",loginState.id);
            formData.append("btnDeleteCustomer","delete");
            formData.append("id",pid);
            var options = {body:formData,method:"POST"};
            fetch(url,options).then(res=>res.json()).then(result=>{
                resolve(result);
            }).catch(e=>reject(e));
        })
    }
    if(loginState){
        return(<>
        <Header auth={loginState}/>
        <section className="dashboard">
        {feedback && feedback.length > 0 ?<div className="feedback col-md-8 col-lg-8 col-sm-10 py-2 danger" id="feedback">{feedback}</div>:null}
    <div className="container">
     <span className="actions-wide">
        <p className="large-text">Customers</p>
        <button className="btn bg-success" onClick={()=>navigate("/customers/new",{replace:false,state:storedData})}>Add</button>
    </span>
     <div className="details-list" id="list_container">
     {customers.map(r=>{
        return <CustomerItem data={r} key={r.id} onDelete={()=>confirmDelete(r.id)} onClick={()=>{navigate("/customers/"+r.id,{replace:false,state:storedData})}} onEdit={()=>{navigate("/customers/edit/"+r.id,{replace:true,state:storedData})}} />
     })
     }
    </div></div>
    </section>
        </>)
    }
    else return <Login target="/customers"/>
}
export default Customers;