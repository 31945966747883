import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Header from "../home/header";
import UTILS from "../../utils/commons"

const TransactionDetail=props=>{
    var location = useLocation();
    var navigate = useNavigate();
    var storedData = JSON.parse(JSON.stringify(location.state));
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    var {id} = useParams();
    var detail = storedData.transactions.find(i=>i.id === id);
    console.log('details: ',detail);
    detail.paid = 0;
    storedData.payments.forEach(p=>{
        if(p.reference === id) detail.paid += parseInt(p.amount);
    });

    
    if(loginState){
        return(<>
            <Header auth={loginState}/>
            <section className="box">
                <div className="action-buttons"><span className="material-icons" onClick={()=>{navigate(-1,{replace:true,state:storedData})}}>close</span></div>
                <div className="medium-text">Transaction Details</div>
                <div className="content-top my-2">
                    <div className="product-details">
                        
                        <div className="my-2">
                            <label>Date</label>
                            <span>{UTILS.formatDate(detail.date_created*1000)}</span>
                        </div>
                        <div className="my-2">
                            <label>Invoice No</label>
                            <span><span>{detail.invoice_no}</span>&nbsp;&nbsp;<a href={UTILS.IMG_URL+"/"+detail.file} target="_blank" rel="noreferrer">view file</a></span>
                        </div>
                        <div className="my-2">
                            <label>Sales Rep</label>
                            <span className="text-primary clickable" onClick={()=>{navigate("/reps/"+detail.rep.id,{replace:false,state:storedData})}}>{detail.rep.fname+" "+detail.rep.lname}</span>
                        </div>
                        <div className="my-2">
                            <label>Product</label>
                            <span className="text-primary clickable"  onClick={()=>{navigate("/products/"+detail.product_detail.id,{replace:false,state:storedData})}}>{detail.product_detail.name}</span>
                        </div>
                        <div className="my-2">
                            <label>Product Pack-Size</label>
                            <span>{detail.product_detail.pack_size}</span>
                        </div>
                        <div className="my-2">
                            <label>Quantity</label>
                            <span>{UTILS.thousandSeparator(detail.quantity)} Cartons</span>
                        </div>
                        
                    </div>
                    <div className="product-details">
                    <div className="my-2">
                            <label>Customer</label>
                            <span className="text-primary clickable" onClick={()=>{navigate("/customers/"+detail.customer_detail.id,{replace:false,state:storedData})}}>{detail.customer_detail.name}</span>
                        </div>
                        <div className="my-2">
                            <label>Transaction Type</label>
                            <span>{parseInt(detail.type) === 0 ? "Cash Sale": "Credit Sale"}</span>
                        </div>
                        <div className="my-2">
                            <label>Unit Price (Tsh)</label>
                            <span>{UTILS.thousandSeparator(detail.price)}</span>
                        </div>
                        <div className="my-2">
                            <label>Total Cost (Tsh)</label>
                            <span>{UTILS.thousandSeparator(detail.cost)}</span>
                        </div>
                        
                        <div className="my-2">
                            <label>Amount Paid (Tsh)</label>
                            <span>{UTILS.thousandSeparator(detail.paid)}</span>
                        </div>
                        <div className="my-2">
                            <label>Amount Due (Tsh)</label>
                            <span>{UTILS.thousandSeparator(detail.cost - detail.paid)}</span>
                        </div>
                        <div className="my-2">
                            {detail.cost > detail.paid ?<span className="clickable" onClick={()=>{navigate("/payments/new",{replace:true,state:storedData})}}>Make Payment</span>:null}
                        </div>
                    </div>
                </div>
            </section>
        </>)
    }

}
export default TransactionDetail;