import React from "react";
import Header from "./header";
const Help=props=>{

    return(
        <>
            <Header auth={null}/>
            <section className="dashboard">
                <div className="content-full">      
                    <div className="col-md-10 col-lg-10 col-sm-10 offset-1 wide_form">
                        <span className="medium-text col-lg-10 col-md-10 text-center">HELP</span>
                        <p>Help topics will be available in future releases</p>
                    </div>
                </div>
            </section>
        </>)
}
export default Help;