import React from "react";

const NotFound=(props)=>{
   
        return(
            
                 <main className="col-md-10 ms-sm-auto col-lg-10 px-md-4 mx-auto my-4">
                    <h1>404: Page Not Found</h1>   
                </main>     
             
        )
    }
   
export default NotFound;