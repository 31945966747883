import './main.css';
import CustomRouter from './components/router';

function App() {
  return (
    <div className="App">
      {/* <Login /> */}
     <CustomRouter />
    </div>
  );
}

export default App;
