import React from "react";
import { BrowserRouter as Router,Routes, Route } from "react-router-dom";
// import { Component } from "react";
import Login from "./home/login_form";
import Dashboard from "./home/dashboard";
import SignupForm from "./home/signup_form";
import RepForm from "./reps/rep_form";
import NotFound from "./home/404";
import Reps from "./reps/reps";
import ProductForm from "./products/product_form";
import ProductList from "./products/products";
import ProductDetail from "./products/product_detail";
import ProductEditForm from "./products/product_edit_form";
import RepDetail from "./reps/rep_detail";
import RepEditForm from "./reps/rep_edit_form";
import SupplierList from "./suppliers/suppliers";
import SupplierDetail from "./suppliers/supplier_detail";
import SupplierForm from "./suppliers/supplier_form";
import SupplierEditForm from "./suppliers/supplier_edit_form";
import Inventory from "./inventory/inventory";
import ReceiveInventory from "./inventory/receive_inventory";
import IssueInventory from "./inventory/issue_inventory";
import Customers from "./customers/customers";
import CustomerForm from "./customers/customer_form";
import CustomerEditForm from "./customers/customer_edit_form";
import CustomerDetail from "./customers/customer_details";
import Report from "./reports/report";
import SaleForm from "./reps/sale_form";
import Settings from "./home/settings";
import Help from "./home/help";
import PasswordResetForm from "./home/password_reset";
import InventoryDetail from "./inventory/inventory_detail";
import Payments from "./payments/payments";
import PaymentForm from "./payments/payment_form";
import TransactionList from "./payments/transactions";
import TransactionDetail from "./payments/transaction_detail";
import ReportsCredits from './reports/supplier_credit'
class CustomRouter extends React.Component {
  constructor(props){
    super(props);
    this.handleAuth = this.handleAuth.bind(this);
  }
  handleAuth(){
    console.log("hello")
  }
  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signin" element={<Login />} />
          <Route path="/signup" element={<SignupForm />} />
          <Route
            path="/dashboard" element={<Dashboard />}
          />
          <Route
            path="/reports" element={<Report />}
          />
           <Route
            path="/reps" element={<Reps />}
          />
           <Route
            path="/reps/new" element={<RepForm />}
          />
          <Route
           path="/reps/:id" element={<RepDetail />}
         />
          <Route
           path="/reps/edit/:id" element={<RepEditForm />}
         />
          <Route
            path="/customers" element={<Customers />}
          />
           <Route
            path="/customers/new" element={<CustomerForm />}
          />
          <Route
           path="/customers/:id" element={<CustomerDetail />}
         />
          <Route
           path="/customers/edit/:id" element={<CustomerEditForm />}
         />
          
         
         <Route
          path="/suppliers/new" element={<SupplierForm/>}
        />
        <Route
         path="/suppliers/edit/:id" element={<SupplierEditForm/>}
       />
          <Route
          path="/reports/credits" element={<ReportsCredits />}
        /> <Route
        path="/suppliers/:id" element={<SupplierDetail/>}
      /> <Route
        path="/suppliers" element={<SupplierList/>}
      />
          <Route
           path="/inventory/:id" element={<InventoryDetail />}
         />
          <Route
           path="/inventory/receive" element={<ReceiveInventory />}
         />
         <Route
          path="/inventory/issue" element={<IssueInventory />}
        />
           <Route
            path="/inventory" element={<Inventory />}
          /> 
          <Route path="/signout" element={<Login />} />
          <Route path="/reset" element={<PasswordResetForm/>} />
          <Route path="*" element={<NotFound />} /> 
          <Route path="/products" element={<ProductList/>} />
          <Route path="/products/new" element={<ProductForm/>} />
          <Route path="/products/edit/:id" element={<ProductEditForm/>} />
          <Route path="/products/:id" element={<ProductDetail/>} />
          <Route path="/sales" element={<SaleForm/>}/>
          <Route path="/settings" element={<Settings/>}/>
          <Route path="/help" element={<Help/>}/>
         
          <Route
            path="/payments/new" element={<PaymentForm />}
          /> <Route
            path="/payments" element={<Payments />}
          />
          <Route
            path="/transactions" element={<TransactionList />}
          />
          <Route
            path="/transactions/:id" element={<TransactionDetail />}
          />
        </Routes>
      </Router>
    );
  }
}
export default CustomRouter;
