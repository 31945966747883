import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useState } from "react";
import Header from "../home/header";
import UTILS from "../../utils/commons"
import CreditItem from "./credit_item";
import commons from "../../utils/commons";
import CreditReportHeadRow from "./credit_table_head";

const ReportsCredits=()=>{
    let navigate = useNavigate();
    var location = useLocation();
    const loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const storedData = JSON.parse(JSON.stringify(location.state));
    let issues = storedData.issues;
    let inventory = storedData.inventory;
    let transactions = storedData.transactions;
    let payments = storedData.payments ? storedData.payments.filter(p=>parseInt(p.payment_type) === 1):[];
    console.log("iv: ",payments);
    const [data,setData] = useState([]);
    const [filteredData,setFilteredData] = useState(data)
    var heads=[{text:"Date",align:"text-left"},{text:"Invoice",align:"text-left"},{text:"Supplier",align:"text-left"},{text:"CIF Amount (Tsh)",align:"text-right"},{text:"Amount Paid (Tsh)",align:"text-right"},{text:"Balance (Tsh)",align:"text-right"}];
      
    const prepareData=()=>{
        var d=[];
            if(payments.length > 0){
                d = inventory.map(iv=>{
                    let invNew = JSON.parse(JSON.stringify(iv));
                    let pay= payments.filter(tx=>tx.account.id === iv.supplier.id && iv.id == tx.reference).map(i=>parseInt(i.amount)).reduce((a,b)=>a+b,0);
                    invNew.balance = parseInt(invNew.cif) - pay;
                    invNew.paid = pay;
                return invNew;
                }).filter(i=>i.balance > 0)
            }
            else{
                d = inventory.map(i=>{
                    i.paid = 0;i.balance = i.cif;
                }).filter(i=>i.balance > 0);
            }
        
        setData(d);
        setFilteredData(d);
        
    }  
    const search=(e)=>{
        let keyword = e.target.value.trim().toLowerCase();
        let result = data;
        if(keyword.length > 0){
            result = data.filter(d=>d.supplier.name.toLowerCase().includes(keyword) || d.invoice_no.toLowerCase().includes(keyword));
        }   
        setFilteredData(result);
    } 
    useEffect(()=>{
        prepareData(data);
    },[]);
    if(loginState){
        return(<>
        <Header auth={loginState}/>
        <section className="dashboard">
        <div className="container">
            <span className="actions-wide">
                <p className="large-text">Supplier Credits</p>
                <div className="d-flex "><button className="btn bg-success  d-flex align-items-center mx-2" onClick={()=>navigate("/payments/new",{replace:false,state:storedData})}>Pay Supplier</button></div>
                
            </span>
            <input className="form-control" placeholder="search" type="text" onChange={search} />
            <div className="details-list">
                <CreditReportHeadRow data={heads} type="inventory"/>
                {filteredData.length > 0 ? filteredData.map((p,index)=>{
                return <CreditItem sn={index} data={p} key={commons.randomKey()} source={filteredData}/>
            }):<span>No data</span>}
            </div>
        </div>
      </section>
        </>)
    }

}
export default ReportsCredits;