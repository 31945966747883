import React, { useEffect, useState } from "react";
import Header from "../home/header";
import TxnItem from "./report_transaction_item";
import UTILS from '../../utils/commons';
import ReportHeadRow from "./report_header";
import IssueReportItem from "./issue_report_item";
import InventoryReportItem from "./inventory_report_item";
import { Doughnut} from "react-chartjs-2";
import { useLocation,useNavigate } from "react-router";
import PaymentItem from "../payments/payment_item";
const Report = props=>{
    var location = useLocation();
    const navigate = useNavigate();
    const loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const storedData = JSON.parse(JSON.stringify(location.state));
   
    var defaultHeads=[{text:"Date",align:"text-left"},{text:"Type",align:"text-left"},{text:"Invoice No.",align:"text-left"},{text:"Quantity (cartons)",align:"text-right"},{text:"Sales Rep",align:"text-left"},{text:"Customer",align:"text-left"},{text:"Product",align:"text-left"},{text:"Amount (Tsh.)",align:"text-right"}];
    const [data,setData] = useState(storedData.transactions);
    const [filteredData,setFilteredData] = useState(data);
    const [heads,setHeads] = useState(defaultHeads);
    const [reportType,setReportType] = useState("all sales");
    const [startDate,setStartDate] = useState(0);
    const [endDate,setEndDate] = useState(0);
    const [groups,setGroups] = useState([]);
    const [groupData,setGroupData] = useState([]);
    const [groupby,setGroupby] = useState("");
    const [groupItems,setGroupItems] = useState(["Rep",'Product',"Invoice"]);
    const [format,setFormat] = useState("list");
    const [chartData,setChartData] = useState({});
    const [totalExpenses,setExpenses] = useState(0);
    const [totalSales,setSales] = useState(0);
    const [totalStock,setStock] = useState(0);
    const [totalCredit,setCredit] = useState(0);
    const [totalDebt,setDebt] = useState(0);
    const [totalPurchases,setPurchases] =useState(0);
    
    const getData=(type)=>{
        var d = [];
        switch(type.toLowerCase()){
            case "all sales":
                d = storedData.transactions;
                setHeads(defaultHeads);
                break;
            case "cash sales":
                d = storedData.transactions.filter(t=>parseInt(t.type)===0);
                setHeads(defaultHeads);
                break;
            case "credit sales":
                d = storedData.transactions.filter(t=>parseInt(t.type)===1);
                setHeads(defaultHeads);
                break;
            case "payment history":
                d = storedData.payments.map(p=>{
                    let k = JSON.parse(JSON.stringify(p));
                    let date = new Date(p.date_created*1000);
                    k.month = date.getMonth();
                    k.year = date.getFullYear();
                    k.date = UTILS.formatDate(p.date_created*1000);
                    return k;
                });
                setHeads([{text:"Date",align:"text-left"},{text:"Account",align:"text-left"},{text:"Reference",align:"text-left"},{text:"Amount (Tsh)",align:"text-right"},{text:"Proof of payment",align:"text-left"},{text:"Owner",align:"text-left"}]);
                break;
            case "stock":
            
                var    h=[{text:"Date",align:"text-left"},{text:"Product",align:"text-left"},{text:"Quantity (cartons)",align:"text-right"},{text:"Amount (Tsh)",align:"text-right"},{text:"Invoice No.",align:"text-left"}];
                    d = storedData.issues.map((is,i)=>{
                        let k = JSON.parse(JSON.stringify(is));
                        storedData.issues.forEach((x,y)=>{
                            if(x.rep.id === is.rep.id && x.product.id === is.product.id && x.invoice_no === is.invoice_no && i !== y){
                                k.quantity = parseInt(k.quantity)+ parseInt(x.quantity);
                                storedData.issues.splice(i,1);
                            }
                        });
                        k.date = UTILS.formatDate(Date.now())
                        return k;
                    });
                    if(storedData.transactions.length > 0){
                        d = d.map(iv=>{
                            let x = JSON.parse(JSON.stringify(iv));
                            storedData.transactions.forEach(is=>{
                                if(x.rep.id === is.rep.id && is.product === iv.product.id && is.invoice_no === iv.invoice_no){
                                    x.quantity = parseInt(x.quantity) - parseInt(is.quantity);
                                }
                            });
                            return x;
                        });
                    }
                    d = d.map(d=>{
                        let p = d;
                        p.amount = parseInt(d.quantity) * parseInt(d.price);
                        return p;
                    })
                    console.log("d: ",d)
                // }
                setHeads(h);
                break;
                case "istock":
               var h=[{text:"Date",align:"text-left"},{text:"Product",align:"text-left"},{text:"Quantity (cartons)",align:"text-right"},{text:"Amount (Tsh)",align:"text-right"},{text:"Invoice No.",align:"text-left"},{text:"Supplier",align:"text-left"}];
                if(parseInt(loginState.level) === UTILS.ADMIN){
                    d = storedData.inventory;
                    
                    // if(type ==="stock" && storedData.issues.length > 0){
                        
                    //     d = d.map(iv=>{
                    //         let x = JSON.parse(JSON.stringify(iv));
                    //         storedData.issues.forEach(is=>{
                    //             if(is.product.id === iv.product.id && is.invoice_no === iv.invoice_no){
                    //                 x.quantity = parseInt(x.quantity) - parseInt(is.quantity);
                    //             }
                    //         });
                    //         x.date = UTILS.formatDate(Date.now())
                    //         return x;
                    //     });
                    // }
                    // else{
                        // h=[{text:"Date",align:"text-left"},{text:"Product",align:"text-left"},{text:"Quantity (cartons)",align:"text-right"},{text:"Amount (Tsh)",align:"text-right"},{text:"Invoice No.",align:"text-left"},{text:"Supplier",align:"text-left"}];
                        d = storedData.inventory.map(i=>{
                            let k = JSON.parse(JSON.stringify(i));
                            k.price = i.buying_price;
                            k.amount = k.selling_price * k.quantity;
                            return k;
                        })
                    // }
                }
                setHeads(h);
                    break;
            case "issues":
                var hd=[{text:"Date",align:"text-left"},{text:"Product",align:"text-left"},{text:"Quantity (cartons)",align:"text-right"},{text:"Amount (Tsh)",align:"text-right"},{text:"Invoice No.",align:"text-left"},{text:"Rep",align:"text-left"}];
                
                d = storedData.issues.map((is,i)=>{
                    let k = JSON.parse(JSON.stringify(is));
                    storedData.issues.forEach((x,y)=>{
                        if(x.rep.id === is.rep.id && x.product.id === is.product.id && x.invoice_no === is.invoice_no && i !== y){
                            k.quantity = parseInt(k.quantity)+ parseInt(x.quantity);
                            storedData.issues.splice(i,1);
                        }
                    });
                    return k;
                });
                setHeads(hd);
                break;
            case "summary":
                d = storedData.payments.map(p=>{
                    let k = JSON.parse(JSON.stringify(p));
                    let date = new Date(p.date_created*1000);
                    k.month = date.getMonth();
                    k.year = date.getFullYear();
                    k.date = UTILS.formatDate(p.date_created*1000);
                    return k;
                });
                setSales(storedData.transactions.map(t=>parseInt(t.cost)).reduce((a,b)=>a+b,0));
                setStock(storedData.inventory.map(inv=>{
                    let iv = JSON.parse(JSON.stringify(inv));
                    let q = 0;
                    storedData.issues.forEach(is=>{
                        if(is.invoice_no === inv.invoice_no && is.product.id === inv.product.id) q += parseInt(is.quantity);
                    });
                    iv.cost = (parseInt(inv.quantity)  - q) * inv.selling_price;
                    return iv;
                }).map(t=>parseInt(t.cost)).reduce((a,b)=>a+b,0));
                setPurchases(storedData.inventory.map(inv=>{
                    let iv = JSON.parse(JSON.stringify(inv));
                    iv.cost = parseInt(inv.quantity)  * Number(inv.buying_price);
                    return iv;
                }).map(t=>parseInt(t.cost)).reduce((a,b)=>a+b,0));
                setExpenses(storedData.payments.filter(p=>parseInt(p.payment_type) === 2).map(p=>parseInt(p.amount)).reduce((a,b)=>a+b,0));
                setCredit(storedData.inventory.map(inv=>{
                    let iv = JSON.parse(JSON.stringify(inv));
                    let q = 0;
                    storedData.payments.forEach(is=>{
                        if(is.reference === inv.id) q += parseInt(is.amount);
                    });
                    iv.cost = parseInt(inv.cif) - q;
                    return iv;
                }).map(t=>parseInt(t.cost)).reduce((a,b)=>a+b,0));
                setDebt(storedData.transactions.filter(t=>parseInt(t.type) === 1).map(inv=>{
                    let iv = JSON.parse(JSON.stringify(inv));
                    let q = 0;
                    storedData.payments.forEach(is=>{
                        if(is.reference === inv.id) q += parseInt(is.amount);
                    });
                    iv.cost = parseInt(inv.cost) - q;
                    return iv;
                }).map(t=>parseInt(t.cost)).reduce((a,b)=>a+b,0));
                break;
            
            default:
                d = storedData.transactions;
                break;
        }
        var nd = d;
        console.log("🚀 ~ getData ~ d:", d)
        console.log("🚀 ~ getData ~ d:", d)
        if(startDate !== 0){
            nd = d.filter(x=> x.date_created * 1000 >= startDate);
        }
        if(endDate !== 0){
            nd = nd.filter(x=> x.date_created * 1000 <= endDate);
        }
        setData(nd);
        setFilteredData(nd);
    }
    const handleGroupBy=e=>{
        let val = e.target.value.trim();
        var mydata = [];
        var grs =[];
        switch(val){
            case "rep":
                console.log("🚀 ~ handleGroupBy ~ data:", data)
                console.log("🚀 ~ handleGroupBy ~ data:", data)
                grs = [...new Set(data.map(d=>d.rep.id))];
                grs.forEach((id)=>{
                    var vals=[];
                    data.forEach(d=>{
                        if(d.rep.id === id){
                            vals.push(d);
                        }
                    });
                    let k = {key:id,data:vals};
                    if(vals.length > 0) mydata.push(k);
                });
                break;
            case "account":
                grs = [...new Set(data.map(d=>d.account.id))];
                grs.forEach((id)=>{
                    var vals=[];
                    data.forEach(d=>{
                        if(d.account.id === id){
                            vals.push(d);
                        }
                    });
                    let k = {key:id,data:vals};
                    if(vals.length > 0) mydata.push(k);
                });
                break;
            case "date":
                grs = [...new Set(data.map(d=>d.month+'_'+d.year))];
                grs.forEach((dt)=>{
                    var vals=[];
                    data.forEach(d=>{
                        let parts = dt.split('_');
                        let month = parseInt(parts[0]);
                        let year = parseInt(parts[1]);
                        if(d.month === month && d.year === year){
                            vals.push(d);
                        }
                    });
                    let k = {key:dt,data:vals};
                    if(vals.length > 0) mydata.push(k);
                });
                break;
            case "product":
                grs = [...new Set(data.map(d=>{
                    if(reportType === "stock" || reportType === "issues") return d.product.id;
                    else return d.product_detail.id;
                }))];
                grs.forEach((id)=>{
                    var vals=[];
                    data.forEach(d=>{
                        var p = (reportType === "stock" || reportType === "issues") ? d.product:d.product_detail;
                        if(p.id === id){
                            vals.push(d);
                        }
                    });
                    let k = {key:id,data:vals};
                    if(vals.length > 0) mydata.push(k);
            });
                break;
            case "invoice":
                grs = [...new Set(data.map(d=>d.invoice_no))];
                grs.forEach((id)=>{
                    var vals=[];
                    data.forEach(d=>{
                        if(d.invoice_no === id){
                            vals.push(d);
                        }
                    });
                    let k = {key:id,data:vals};
                    mydata.push(k);
            });
                break;
            default:
                grs = [];
                break;
        }
        
        setGroupby(val);
        setGroupData(mydata);
        setGroups(grs);
        setFormat("list");
    }
    const handleReportType=(e)=>{
        let type = e.target.value;
        setReportType(type);
        reset();
        getData(type);
        if(type === "summary"){
            document.getElementById("start_date").disabled = false;
            document.getElementById("end_date").disabled = false;
            document.getElementById("report_group").disabled = true;
        }
        else if(type ==="stock"){
            setGroupItems(['Product',"Invoice","Rep"]);
            setGroupItems(['Product',"Invoice","Rep"]);
            document.getElementById("start_date").disabled = true;
            document.getElementById("end_date").disabled = true;
        }
        else if(type === "istock"){
            setGroupItems([]);
            document.getElementById("start_date").disabled =false;
            document.getElementById("end_date").disabled = false;
            document.getElementById("report_group").disabled = true;
        }
        else if(type === "payment history"){
            setGroupItems(["Date",'Account']);
            document.getElementById("start_date").disabled = false;
            document.getElementById("end_date").disabled = false;
        }
        else {
            setGroupItems(["Rep",'Product',"Invoice"]);
            document.getElementById("start_date").disabled = false;
            document.getElementById("end_date").disabled = false;
        }
    }
    const handleReportFormatChange=e=>{
        var f = e.target.value;
        var colors = [];
        groups.forEach((g)=>{
            colors.push("#"+Math.floor(Math.random()*16777215).toString(16));
        });
        var labels = [];
        groups.forEach(d=>{
            var k;
            if(groupby === 'product'){
                k = storedData.products.find(p=>p.id === d);
                labels.push(k.name);
            }
            else if(groupby === "rep"){
                k = storedData.reps.find(p=>p.id === d);
                labels.push(k.fname+" "+k.lname);
            }
            else if(groupby === "invoice"){
                labels.push(d);
            }
            
        })
        if(f === "pie"){
            var d=groupData;
            var da = [];
            d.forEach(d=>{
                var sum = reportType.includes("sales") ? d.data.map(d=>d.cost).reduce((a,b)=>a+b,0):d.data.map(d=>d.amount).reduce((a,b)=>a+b,0);
                if(reportType === "stock") sum = d.data.map(d=>d.quantity).reduce((a,b)=>a+b,0);
                da.push(sum);
            });
            var cData = {
                labels:labels,
                datasets:[{
                    label:'Sales',
                    data:da,
                    backgroundColor:colors
                }]
            }
            setChartData(cData);
        
        } 
        
        setFormat(f);
    }
    const reset=()=>{
        setGroups([]);
        setGroupData([]);
        setGroupby("");
        setFormat("list");
    }
    const handleDateChange=(e)=>{
        if(e.target.value && e.target.value.length > 0){
            var date = Date.parse(e.target.value.trim());
            if(e.target.id === "start_date"){
                setStartDate(date);
            }
            else {
                date += 82859000;
                setEndDate(date);
            }   
        }
        else{
            if(e.target.id === "start_date"){
                setStartDate(0);
            }
            else {
                setEndDate(0);
            } 
        }
        reset();
        getData(reportType);
    }
    const search=(e)=>{
        let keyword = e.target.value.trim().toLowerCase();
        let result = data;
        if(keyword.length > 0){
            switch(reportType){
                case "all sales":
                case "cash sales":
                case "credit sales":
                    result = data.filter(d=>d.customer_detail.name.toLowerCase().includes(keyword) || d.product_detail.name.toLowerCase().includes(keyword) || d.rep.fname.toLowerCase().includes(keyword) || d.rep.lname.toLowerCase().includes(keyword));
                    break;
                case "stock":
                case "istock":
                    result = data.filter(d=>d.product.name.toLowerCase().includes(keyword) || d.supplier.name.toLowerCase().includes(keyword));
                    break;
                case "issues":
                    result = data.filter(d=>d.product.name.toLowerCase().includes(keyword) || d.rep.fname.toLowerCase().includes(keyword) || d.rep.lname.toLowerCase().includes(keyword));
                    break;
                case "payment history":
                    result = data.filter(d=>d.account.name.toLowerCase().includes(keyword));
                    break;
                default:
                    break;
            }
        }   
        setFilteredData(result);
    }
    useEffect(()=>{
        getData(reportType)
    },[reportType,startDate,endDate,groups])
    if(loginState){
        return(
        <>
            <Header auth={loginState}/>
            <section className="dashboard">
                <div className="content-top">
                <div className="col-md-12 col-lg-12 col-sm-10">
                    <span className="medium-text col-md-12 col-lg-12 col-sm-10 text-center my-2" id="report_title">User Reports</span>
                    <div className="col-md-12 col-lg-12 col-sm-10 row my-3">
                        <div className="col-lg-2 col-md-2 col-sm-10">
                            <label htmlFor="report_type">Report Type</label>
                            <select id="report_type" className="form-control" onChange={(e)=>handleReportType(e)}>
                                <option value="all sales">All Sales</option>
                                <option value="cash sales">Cash Sales</option>
                                <option value="credit sales">Credit Sales</option>
                                <option value="payment history">Payment History</option>
                                <option value="stock">Current Stock at Branch</option>
                                {parseInt(loginState.level) === UTILS.ADMIN ? <>
                                <option value="istock">Imported Stock</option>
                                <option value="issues">Issues</option>
                                <option value="summary">Summary</option>
                                </>:null}
                            </select>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-10">
                        <label htmlFor="report_group">Group By</label>
                        <select id="report_group" className="form-control" value={groupby} onChange={(e)=>handleGroupBy(e)}>
                            <option value="0">--select--</option>
                            {groupItems.map(g=>{
                                return <option key={UTILS.randomKey()} value={g.toLowerCase()}>{g}</option>
                            })
                        }                      
                        </select>
                    </div>
                        <div className="col-lg-2 col-md-2 col-sm-10">
                            <label htmlFor="report_format">Report Format</label>
                            <select id="report_format" className="form-control" disabled={groups.length ===0} value={format} onChange={(e)=>handleReportFormatChange(e)}>
                                <option value="list">List</option>
                                <option value="pie">Pie Chart</option>
                            </select>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-10">
                            <label htmlFor="start_date">Start Date</label>
                            <input type="date" id="start_date" className="form-control" onInput={(e)=>handleDateChange(e)}/>
                                
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-10">
                            <label htmlFor="end_date">End Date</label>
                            <input type="date" id="end_date" className="form-control" onInput={(e)=>handleDateChange(e)}/>
                                
                        </div>
                    </div>
                    
                    {format === "list" ?(
                    <div className="col-md-12 col-lg-12 col-sm-10" id="report_container">
                        
                    <div className="row col-lg-10 col-md-10 offset-1 my-3">
                        <input id="search_report" name="search_report" className="form-control-sm" placeholder="search" onChange={search}/>
                    </div>
                    {reportType === "summary"?<div className="row col-lg-8 col-md-8 col-sm-12 offset-2">
                    <div className="row col-lg-8 col-md-8 item-list">
                        <div className="col-lg-3 col-md-3 col-sm-12">Total Imported Stock</div>
                        <div className="col-lg-3 col-md-3 col-sm-12 text-primary text-right clickable" onClick={()=>navigate('/inventory',{replace:false,state:storedData})}>TZS {UTILS.thousandSeparator(totalPurchases)}</div>
                        {/* <div className="col-lg-2 col-md-2 col-sm-12"></div> */}
                    </div>
                    <div className="row col-lg-8 col-md-8 item-list">
                        <div className="col-lg-3 col-md-3 col-sm-12">Total Sales</div>
                        <div className="col-lg-3 col-md-3 col-sm-12 text-primary text-right clickable" onClick={()=>navigate('/transactions',{replace:false,state:storedData})}>TZS {UTILS.thousandSeparator(totalSales)}</div>
                        {/* <div className="col-lg-2 col-md-2 col-sm-12"></div> */}
                    </div>
                    <div className="row my-2 col-lg-8 col-md-8 item-list">
                        <div className="col-lg-3 col-md-3 col-sm-12">Total Expenses</div>
                        <div className="col-lg-3 col-md-3 col-sm-12 text-primary text-right clickable" onClick={()=>navigate('/payments',{replace:false,state:storedData})}>TZS {UTILS.thousandSeparator(totalExpenses)}</div>
                        {/* <div className="col-lg-3 col-md-3 col-sm-12"></div> */}
                    </div>
                    
                    <div className="row my-2 col-lg-8 col-md-8 item-list">
                        <div className="col-lg-3 col-md-3 col-sm-12">Total Customer Debts</div>
                        <div className="col-lg-3 col-md-3 col-sm-12 text-primary text-right clickable" onClick={()=>navigate('/transactions',{replace:false,state:storedData})}>TZS {UTILS.thousandSeparator(totalDebt)}</div>
                        {/* <div className="col-lg-3 col-md-3 col-sm-12"></div> */}
                    </div>
                    <div className="row my-2 col-lg-8 col-md-8 item-list">
                        <div className="col-lg-3 col-md-3 col-sm-12">Total Supplier Credits</div>
                        <div className="col-lg-3 col-md-3 col-sm-12 text-primary text-right clickable" onClick={()=>navigate('/reports/credits',{replace:false,state:storedData})}>TZS {UTILS.thousandSeparator(totalCredit)}</div>
                        {/* <div className="col-lg-3 col-md-3 col-sm-12"></div> */}
                    </div>
                    <div className="row my-2 col-lg-8 col-md-8 item-list">
                        <div className="col-lg-3 col-md-3 col-sm-12">Current Stock Value</div>
                        <div className="col-lg-3 col-md-3 col-sm-12 text-primary text-right clickable" onClick={()=>navigate('/inventory',{replace:false,state:storedData})}>TZS {UTILS.thousandSeparator(totalStock)}</div>
                        {/* <div className="col-lg-3 col-md-3 col-sm-12"></div> */}
                    </div>
                    </div>:(<>
                    <ReportHeadRow data={heads} type={reportType}/>
                    {groups.length === 0 ? filteredData.map((t,i)=>{
                        if(reportType === 'stock' || reportType === "istock") return <InventoryReportItem type={reportType} data={t} key={UTILS.randomKey()} pos={i} isGrouped={groups.length >0} source={storedData}/>;
                        else if(reportType === 'issues') return <IssueReportItem data={t} key={UTILS.randomKey()} pos={i} isGrouped={groups.length >0} source={storedData}/>;
                        else if(reportType === "payment history") return <PaymentItem data={t} key={UTILS.randomKey()} pos={i} isGrouped={groups.length >0} source={storedData}/>
                        else return <TxnItem data={t} key={UTILS.randomKey()} pos={i} isGrouped={groups.length >0} group={groupby} source={storedData}/>
                    }):groupData.map((t,i)=>{
                        if(reportType === 'stock' || reportType ==="istock") return <InventoryReportItem type={reportType} data={t.data} key={UTILS.randomKey()} pos={i} isGrouped={groups.length >0} group={groupby} source={storedData}/>;
                        else if(reportType === 'issues') return <IssueReportItem data={t.data} key={UTILS.randomKey()} pos={i} isGrouped={groups.length >0} group={groupby} source={storedData}/>;
                        else if(reportType === "payment history") return <PaymentItem data={t.data} key={UTILS.randomKey()} pos={i} isGrouped={groups.length >0} group={groupby} source={storedData}/>
                        else return <TxnItem data={t.data} key={UTILS.randomKey()} pos={i} isGrouped={groups.length >0} group={groupby} source={storedData}/>})
                    }</>)}
                    </div>):
                    <div className="col-md-12 col-lg-12 col-sm-10 chart_container">
                    <Doughnut options={{
                        responsive:true,
                        pieceLabel:{
                            mode:'value',precision:0
                        },
                        plugins:{
                            legend:{
                                display:true,position:'left'
                            },
                            title:{
                                display:true,
                                text:reportType.toUpperCase()+((groupby)?" GROUPED BY "+groupby.toUpperCase():"")
                            }
                        },
                        datalabels:{display:true,anchor:'end',color:'black'},maintainAspecRatio:false}} data={chartData}/>
                    </div>}
                </div>
                </div>
            </section>
        </>)
    }

}
export default Report;