import React from "react";
import Header from "../home/header";
import repImage from '../../img/reps.svg'; 
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import Login from "../home/login_form";
import UTILS from "../../utils/commons"
import Loading from "../home/Loading";

const RepForm = (props)=>{
let navigate = useNavigate();
let location = useLocation();

const loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
var storedData = location.state;
const [isLoading,setIsLoading] = useState(false);
const [feedback,setFeedback] = useState("");

const handleSubmit = (e)=>{
  e.preventDefault();
  var form = e.target;
  var rep = {};
  rep.fname = form.fname.value.trim();
  rep.lname = form.lname.value.trim();
  rep.service_area = form.service_area.value.trim();
  rep.phone = form.phone.value.trim();
  rep.email = form.email.value.trim();
  rep.admin = loginState.id;
  rep.password = form.password.value;
  rep.avatar = form.profile_image.files[0];
  saveRep(rep);
}
const uploadImage=(e)=>{
  e.preventDefault();
  const previewImage = document.getElementById("rep_preview");
  let file = e.target.files[0];
  if(file){
    //  data.image = file;
      let reader = new FileReader();
      reader.addEventListener('load',()=>{
          const url = URL.createObjectURL(file);
          previewImage.src = url;
          // saveProduct(prod);
      },false);
      reader.readAsDataURL(file);
  }
           
} 
const saveRep=(rep)=>{
  setIsLoading(true);
  var formData = new FormData();
  formData.append("fname",rep.fname);
    formData.append("lname",rep.lname);
    formData.append("email",rep.email);
    formData.append("phone",rep.phone);
    formData.append("service_area",rep.service_area);
    formData.append("admin",rep.admin);
    formData.append("password",rep.password);
    formData.append("image",rep.avatar);
    formData.append("btnAddRep","Add");
    
    fetch(UTILS.URL+"?tag=rep",{method:"POST",body:formData,cache:'reload'})
    .then(res=>res.json())
    .then(result=>{
        if(result.code == 0){
            storedData.reps = result.reps;
            navigate("/reps",{replace:true,state:storedData})
        }
        else{
          setFeedback(result.msg);
        }
    })
    .catch(e=>{
        console.log("error: ",e);
        setFeedback("Oops! Something went wrong");
    })
    .finally(()=>{
      setIsLoading(false);
    })
}
if(loginState){
    return(<>
    <Header auth={loginState}/>
    <section className="dashboard"> {feedback.length > 0 ?<div className="feedback col-md-8 col-lg-8 col-sm-10 py-2 danger" id="feedback">{feedback}</div>:null}
          
    <div className="content-full">
      <div className="logo">
        <img id="rep_preview" src={repImage} alt="Sales rep feature" />
        <p className="medium-text">Add Sales Rep</p>
      </div>
      <div className="col-md-4 col-lg-4 col-sm-10">
        <form id="new_rep_form"
          className="col-md-8 col-lg-8 col-sm-10"
          method="post" onSubmit={handleSubmit}
        >
        <div className="form-group my-2">
            <input
              type="text"
              className="form-control"
              id="fname"
              name="fname"
              placeholder="First name"
            />
          </div>
          <div className="form-group my-2">
            <input
              type="text"
              className="form-control"
              id="lname"
              name="lname"
              placeholder="Last name"
            />
          </div>
          <div className="form-group my-2">
            <label htmlFor="profile_image">Upload Profile Image</label>
            <input onChange={(e)=>uploadImage(e)}
              type="file"
              className="form-control"
              id="profile_image"
              name="profile_image"
            />
          </div>
          <div className="form-group my-2">
            <input
              type="text"
              className="form-control"
              id="service_area"
              name="service_area"
              placeholder="Service Area"
            />
          </div>
          <div className="form-group my-2">
            <input
              type="number"
              className="form-control"
              id="phone"
              name="phone"
              placeholder="Phone Number"
            />
          </div>
          <div className="form-group my-2">
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="E-mail"
            />
          </div>

          <div className="form-group my-2">
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              placeholder="Password"
            />
          </div>
         <div className="form-group my-2 row">
          <div className="my-2 col-md-6 col-lg-6 col-sm-10">
            {isLoading ? <Loading />:
            <input
              type="submit"
              className="form-control bg-success"
              id="btnSubmit"
              name="btnSubmit"
              value="SUBMIT"
            />}
          </div>
          <div className="my-2 col-md-6 col-lg-6 col-sm-10">
            <input onClick={()=>{navigate("/reps",{replace:true,state:storedData})}}
              type="button"
              className="form-control"
              value="Cancel"
            />
          </div>
        </div>
        </form>
      </div>
    </div>
    </section></>)
}
else return <Login target="/reps"/>
}
export default RepForm;