import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Header from "../home/header";
import Login from "../home/login_form";
import feature from "../../img/suppliers.svg";
import Loading from "../home/Loading";
import UTILS from "../../utils/commons";


const SupplierForm = props=>{
    let navigate = useNavigate();
    var location = useLocation();
    const loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    var storedData = location.state;
    const [feedback,setFeedback] = useState("");
    const [isLoading,setIsLoading] = useState(false);

    const saveSupplier=(supplier)=>{
        var formData= new FormData();
    formData.append("name",supplier.name);
    formData.append("country",supplier.country);
    formData.append("phone",supplier.phone);
    formData.append("address",supplier.address);
    formData.append("email",supplier.email);
    formData.append("contact",supplier.contact);
    formData.append("user_id",supplier.user_id);
    formData.append("btnAddSupplier","Add");
   
    fetch(UTILS.URL+"?tag=suppliers",{
        body:formData,method:"POST"
    }).then(res=>res.json())
    .then(response=>{
        if(parseInt(response.code) === 0){
            storedData.suppliers = response.suppliers;
            navigate("/suppliers",{replace:true,state:storedData});
        }
        else{
            setFeedback(response.msg);
        }
    })
    .catch(e=>{
        console.log("error: ",e);
        setFeedback(e);
    })
    .finally(()=>{
        setIsLoading(false);
    })
    }
    const handleSubmit=(e)=>{
        e.preventDefault();
        setIsLoading(true);
        let name = document.getElementById("name").value;
        let country = document.getElementById("country").value;
        let phone = document.getElementById("phone").value;
        let contact_person = document.getElementById("contact").value;
        let email = document.getElementById("email").value;
        let address = document.getElementById("address").value; 
        var user_id = loginState.id;
        let sup = {name:name,country:country,email:email,phone:phone,address:address,contact:contact_person,user_id:user_id};
        saveSupplier(sup);
    }
    if(loginState){
        return(<>
        <Header auth={loginState}/>
        <section className="dashboard"> 
        {feedback.length > 0? <span className="feedback col-md-8 col-lg-8 col-sm-10 py-2 danger" id="feedback">{feedback}</span>:null}
     
    <div className="content-full">
      <div className="logo">
        <img src={feature} alt="Supplier feature" />
        <p className="medium-text">Add Supplier</p>
      </div>
      <div className="col-md-4 col-lg-4 col-sm-10">
        <form id="new_supplier_form"
          className="col-md-8 col-lg-8 col-sm-10"
          method="post" onSubmit={handleSubmit}
        >
        <div className="form-group my-2">
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Company name"
            />
          </div>
          <div className="form-group my-2">
            <input
              type="text"
              className="form-control"
              id="contact"
              name="contact"
              placeholder="Contact Person"
            />
          </div>
          <div className="form-group my-2">
              <label htmlFor="country">Select Country</label>
            <select
              className="form-control"
              id="country"
              name="country">
                {UTILS.COUNTRIES.map(c=>{
                    return <option key={c}>{c}</option>
                })}
            </select>
          </div>
          <div className="form-group my-2">
            <input
              type="text"
              className="form-control"
              id="address"
              name="address"
              placeholder="address"
            />
          </div>
          <div className="form-group my-2">
            <input
              type="number"
              className="form-control"
              id="phone"
              name="phone"
              placeholder="Phone Number"
            />
          </div>
          <div className="form-group my-2">
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="E-mail"
            />
          </div>

         
         <div className="form-group my-2 row">
          <div className="my-2 col-md-6 col-lg-6 col-sm-10">
          {isLoading ? <Loading />:
            <input
              type="submit"
              className="form-control bg-success"
              id="btnSubmit"
              name="btnSubmit"
              value="SUBMIT"
            />}
          </div>
          <div className="my-2 col-md-6 col-lg-6 col-sm-10">
            <input onClick={()=>{navigate("/suppliers",{replace:true,state:storedData})}}
              type="button"
              className="form-control"
              value="Cancel"
            />
          </div>
        </div>
        </form>
      </div>
    </div>
    </section>
        </>)
    }
    return <Login target="/suppliers"/>
}
export default SupplierForm;