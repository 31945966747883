import React from "react";
import { useNavigate } from "react-router";
import UTILS from '../../utils/commons';


const IssueReportItem = props=>{
    var navigate = useNavigate();
    var storedData = props.source;
    var d = props.data;
    var date = new Date(parseInt(d.date_created)*1000);
    d.date = UTILS.getMonth(date.getMonth())+" "+date.getDate()+", "+date.getFullYear();


    var group;
    var sum = 0;
    var sumQ = 0;
    if(props.isGrouped){
        switch(props.group){
            case "rep":
                group = d[0].rep.fname+ " "+d[0].rep.lname;
                break;
            case "product":
                group = d[0].product.name+ ' ('+d[0].product.pack_size+")";
                break;
            case "invoice":
                group = "Invoice no: "+d[0].invoice_no;
                break;
            default:
                break;
        }
        d = d.map(x=>{
            let k = x;
            var date = new Date(parseInt(x.date_created)*1000);
            k.date = UTILS.getMonth(date.getMonth())+" "+date.getDate()+", "+date.getFullYear();
            k.quantity = parseInt(x.quantity);
            k.amount = parseInt(x.amount);
            return k;
        });
        
        sum = d.map(x=>x.amount).reduce((a,b)=>a+b);
        sumQ= d.map(x=>x.quantity).reduce((a,b)=>a+b);
    }
    return(<>
        {props.isGrouped ? <div className="row my-2 col-lg-12 col-md-12 item-list bg-success mx-auto">
        <span className="col-lg-4 col-md-4 col-sm-12">{group}</span>
        <span className="col-lg-2 col-md-2 col-sm-12 text-right">{UTILS.thousandSeparator(sumQ)}</span>
        <span className="col-lg-2 col-md-2 col-sm-12 text-right">{UTILS.thousandSeparator(sum)}</span>
        <span className="col-lg-4 col-md-4 col-sm-12 text-left"></span>
        
        </div>:null}
        {props.isGrouped ? d.map(d=>{
            return (<div key={UTILS.randomKey()} className={"row my-2 col-lg-12 col-md-12 item-list mx-auto "+(props.pos%2===0 ? "bg-light":"")} >
            <span className="col-md-2 col-lg-2 col-sm-12 bold text-left">{d.date}</span>
            <span className="col-md-2 col-lg-2 col-sm-12 bold text-left" onClick={()=>{navigate("/products/"+d.product.id)}}>{d.product.name+" ("+d.product.pack_size+")"}</span>
            <span className="col-md-2 col-lg-2 col-sm-12 bold text-right">{d.quantity}</span>
            <span className="col-md-2 col-lg-2 col-sm-12 bold text-right">{UTILS.thousandSeparator(d.amount)}</span>
            <span className="col-md-2 col-lg-2 col-sm-12 bold text-left">
                {d.invoice ?
                <a href={"/data/"+d.invoice} target="_blank" rel="noreferrer">{d.invoice_no}</a>:d.invoice_no
                }</span>
            <span className="col-md-2 col-lg-2 col-sm-12 bold text-left" onClick={()=>{navigate('/reps/'+d.rep.id)}}>{d.rep.fname+" "+d.rep.lname}</span>
            
        </div>)
        }):
        <div className={"row my-2 col-lg-12 col-md-12 item-list mx-auto "+(props.pos%2===0 ? "bg-light":"")} >
        <span className="col-md-2 col-lg-2 col-sm-12 bold text-left">{d.date}</span>
        <span className="col-md-2 col-lg-2 col-sm-12 bold text-left" onClick={()=>{navigate("/products/"+d.product.id)}}>{d.product.name+" ("+d.product.pack_size+")"}</span>
        <span className="col-md-2 col-lg-2 col-sm-12 bold text-right">{d.quantity}</span>
        <span className="col-md-2 col-lg-2 col-sm-12 bold text-right">{UTILS.thousandSeparator(d.amount)}</span>
        <span className="col-md-2 col-lg-2 col-sm-12 bold text-left">{d.invoice ?
                <a href={"/data/"+d.invoice} target="_blank" rel="noreferrer">{d.invoice_no}</a>:d.invoice_no
                }</span>
        <span className="col-md-2 col-lg-2 col-sm-12 bold text-left" onClick={()=>{navigate('/reps/'+d.rep.id)}}>{d.rep.fname+" "+d.rep.lname}</span>
        
    </div>}</>)
}
export default IssueReportItem;