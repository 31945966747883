import React from "react";
import { useLocation, useNavigate } from "react-router";
import UTILS from "../../utils/commons";

const InventoryItem = props=>{
    var navigate = useNavigate();
    var location = useLocation();
    var storedData = JSON.parse(JSON.stringify(location.state));
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    var data = props.data;
    if(parseInt(loginState.level)=== UTILS.ADMIN){
       data.amount = data.selling_price * data.quantity;
    }
    else{
        data.amount = data.price * data.quantity;
    }
    
    return(<div className="row my-2 item-list ">
    <div key={UTILS.randomKey()} className="col-lg-2 col-md-2 ">{props.sn+1}</div>
        <div key={UTILS.randomKey()} className="col-lg-2 col-md-2 ">{data.product.name}</div>
        <div key={UTILS.randomKey()} className="col-lg-2 col-md-2 ">{data.product.pack_size}</div>
        <div key={UTILS.randomKey()} className="col-lg-2 col-md-2 text-right">{data.quantity}</div>
        <div key={UTILS.randomKey()} className="col-lg-2 col-md-2 text-right">{UTILS.thousandSeparator(data.amount)}</div>
        <div key={UTILS.randomKey()} className="col-lg-2 col-md-2 ">{<span className="clickable" onClick={()=>{navigate("/inventory/"+data.id,{state:storedData,replace:false})}}>Invoice No: {data.invoice_no}</span>}</div>
        
         </div>)
}
export default InventoryItem;