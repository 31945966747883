import React from "react";
import Header from "../home/header";
import {useLocation, useNavigate} from 'react-router-dom';
import Login from "../home/login_form";
import { useEffect,useState } from "react";
import RepItem from "./rep_item";
import UTILS from '../../utils/commons';

const Reps=(props)=>{
    let navigate = useNavigate();
    let location = useLocation();
    var storedData = location.state;
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));

    const [reps,setReps] = useState([]);
    var data = storedData.reps.filter(p=>parseInt(p.status)===0);
    const getReps=()=>{
        setReps(data);
    }
    useEffect(()=>{
        getReps(reps);
    },[]);
    const confirmDelete = (itemId)=>{
        let message = "Are you sure you want to delete this rep?";
        if(window.confirm(message)){
        
            deleteRep(itemId).then(result=>{
                if(parseInt(result.code) === 0){
                    storedData.reps = result.reps;
                    setReps(storedData.reps.filter(p=>parseInt(p.status) === 0));
                }
                
            })
        }
        
    }
    const deleteRep=(pid)=>{
        let rep = storedData.reps.find(p=>p.id === pid);
        return new Promise((resolve,reject)=>{
            let url = UTILS.URL +"?tag=rep";
            var formData = new FormData();
            formData.append("status",1);
            formData.append("user_id",rep.admin);
            formData.append("btnDeleteRep","delete");
            formData.append("id",rep.id);
            var options = {body:formData,method:"POST"};
            fetch(url,options).then(res=>res.json()).then(result=>{
                resolve(result);
            }).catch(e=>reject(e));
        })
    }
    if(loginState){
        return(<>
        <Header auth={loginState}/>
        <section className="dashboard">
    <div className="container">
     <span className="actions-wide">
        <p className="large-text">Sales Reps</p>
        <button className="btn bg-success" onClick={()=>navigate("/reps/new",{replace:false,state:storedData})}>Add</button>
    </span>
     <div className="details-list" id="list_container">
     {reps.map(r=>{
        return <RepItem data={r} key={r.id} onDelete={()=>confirmDelete(r.id)} onClick={()=>{navigate("/reps/"+r.id,{replace:false,state:storedData})}} onEdit={()=>{navigate("/reps/edit/"+r.id,{replace:false,state:storedData})}} />
     })
     }
    </div></div>
    </section>
        </>)
    }
    else return <Login target="/reps"/>
}
export default Reps;