import React,{useEffect, useState} from "react";
import Header from "./header";
import UTILS from '../../utils/commons';
import Loading from "./Loading";
import feature from "../../img/customer.svg";
import { useNavigate } from "react-router";
import Login from "./login_form";

const Settings=props=>{
    var navigate = useNavigate();
    var session = window.sessionStorage.getItem("loginState");
    
    var loginState = (session == "undefined" || session === null || session ==="" || session =="null") ? false:JSON.parse(session);
    const [feedback,setFeedback] = useState("");
    const [hasError,setHasError] = useState(false);
    const [user,setUser] = useState(loginState);
    const [avatar,setAvatar] = useState(null);
    const [file,setFile] = useState(null);
    const [isLoading,setIsLoading] = useState(false);

    const handleChange=e=>{
        let val = e.target.value.trim();
        let id = e.target.id;
        if(user){
           var d = user;
            switch(id){
                case "fname":
                    d.fname = val;
                    break;
                case "lname":
                    d.lname = val;
                    break;
                case "phone":
                    d.phone = val;
                    break;
                case "service_area":
                    d.service_area = val;
                    break;
                default:
                    break;

            }
            setUser(d); 
        }
        
    }
    const handleSubmit=e=>{
        e.preventDefault();
        setIsLoading(true);
        var form = e.target;
        var formData = new FormData();
        var fname = form.fname.value.trim();
        var lname = form.lname.value.trim();
        var phone = form.phone.value.trim();
        var password = form.password.value;
        var service_area;
        if(parseInt(loginState.level) === UTILS.NORMAL) service_area = form.service_area.value.trim();
        
        // var lname = form.lname.value.trim();
        if(fname.toLowerCase() != loginState.fname.toLowerCase()) formData.append("fname",fname.trim());
        if(lname.toLowerCase() !== loginState.lname.toLowerCase()) formData.append("lname",lname.trim());
        formData.append("email",loginState.email);
        if(parseInt(loginState.level) === UTILS.NORMAL){
            if(service_area && service_area.toLowerCase() !== loginState.detail.service_area.toLowerCase()) formData.append("service_area",service_area.trim());
            if(phone && phone !== loginState.detail.phone) formData.append("phone",phone.trim());
        }
        else{
            if(phone && phone !== loginState.phone) formData.append("phone",phone.trim());
        }
        if(password.length > 0) formData.append("password",password);
        formData.append("uid",loginState.id);
        formData.append("level",loginState.level);
        formData.append("btnUpdateUser","update");

        var options={
            method:"POST",body:formData,cache:'reload'
        }
        fetch(UTILS.URL+"?tag=update",options)
        .then(res=>res.json())
        .then(result=>{
            
            if(parseInt(result.code) === 1) {
                setHasError(true);
            }
            else{
                window.sessionStorage.setItem("loginState",JSON.stringify(result.user));
                loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
                setUser(result.user);
                setAvatar(result.user.avatar);
            }
            setFeedback(result.msg);
        })
        .catch(e=>{
            console.log("err: ",e);
            setHasError(true);
            setFeedback("Oops! Something went wrong");
        })
        .finally(()=>{
            setIsLoading(false);
        })
    }
    const handleCameraClick=e=>{
    
        var fileInput = document.getElementById("avatar");
        var previewImage = document.getElementById("avatar_image");
        fileInput.click();
        fileInput.addEventListener('change',(e)=>{
            e.stopPropagation();
            
            let file = e.target.files[0];
            if(file){
                setFile(file);
                let reader = new FileReader();
                reader.addEventListener('load',()=>{
                    const url = URL.createObjectURL(file);
                    previewImage.src = url;
                },false);
                reader.readAsDataURL(file);
                uploadImage(file);
            }
        })
    }
    const uploadImage=(file)=>{
        var formData = new FormData();
        formData.append("id",loginState.id);
        formData.append("image",file);
        formData.append("updateAvatar",'avatar');
        
        fetch(UTILS.URL+"?tag=avatar",{body:formData,method:"POST",cache:"reload"})
        .then(res=>res.json())
        .then(result=>{
            if(parseInt(result.code) === 1) {
                setHasError(true);
            }
            else{
                window.sessionStorage.setItem("loginState",JSON.stringify(result.user));
                loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
                setUser(result.user);
                setAvatar(result.user.avatar);
            }
            setFeedback(result.msg);
        })
        .catch(e=>{
            console.log("err: ",e);
            setHasError(true);
            setFeedback("Oops! Something went wrong");
        })
        .finally(()=>{
            setIsLoading(false);
        })
    }
    useEffect(()=>{
        if(loginState){
            var u = loginState;
            if(parseInt(loginState.level) === UTILS.ADMIN) u = loginState;
            else{
                u.level = loginState.level;
                u.email = loginState.email;
                u.phone = loginState.detail.phone;
                u.service_area = loginState.detail.service_area;
                u.id = loginState.id;
                u.avatar = loginState.detail.avatar;
                u.fname = loginState.fname;
                u.lname = loginState.lname;
            }
            
            setAvatar(u.avatar);
            setUser(u);
        }
        
    },[avatar])
    if(!loginState){
        return <Login target="/settings"/>
    
    }
    else {
        return(
            <>
                <Header auth={loginState}/>
                <section className="dashboard">
                    {feedback.length > 0 ?<div className={"alert text-center "+(hasError?"danger":"success")} id="form-feedback">{feedback}</div>:null}
                    <div className="container">
                        <span className="actions-wide">
                            <p className="large-text">Settings</p>
                            <div className="d-flex flex-column justify-content-center align-items-center border">
                                <img id="avatar_image" src={(avatar) ? (UTILS.IMG_URL+"/"+avatar+"?"+Date.now()):feature} alt="" className="avatar"/>
                            <span className="material-icons btn" onClick={handleCameraClick}>camera</span>
                                <input type="file" id="avatar" name="avatar" accepts="image/*" className="hidden"/>
                            </div>
                            
                        </span>
                        <div className="row" id="">
                            <form id="user_detail_form" method="POST" encType="multipart/form-data" onSubmit={handleSubmit}>
                              
                                <div className="text-center">                                
                                </div>
                                <div className="col-lg-10 col-md-10 row">
                                    <div className="col-lg-4 col-md-4 col-sm-10 offset-sm-1 py-1">
                                        <label htmlFor="fname">First Name</label>
                                        <input className="form-control" autoCapitalize="true" type="text" id="fname" name="fname" placeholder="First name" onChange={(e)=>handleChange(e)} defaultValue={user.fname}/>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-10 offset-sm-1 py-1">
                                        <label htmlFor="lname">Last Name</label>
                                        <input className="form-control" autoCapitalize="true" type="text" id="lname" name="lname" placeholder="Last name" onChange={(e)=>handleChange(e)} defaultValue={user.lname}/>
                                    </div>
                                
                                    <div className="col-lg-4 col-md-4 col-sm-10 offset-sm-1 py-1">
                                        <label htmlFor="phone">Phone</label>
                                        <input className="form-control" type="text" id="phone" name="phone" placeholder="Phone" onChange={(e)=>handleChange(e)} defaultValue={user.phone} autoComplete={false} autoFill/>
                                    </div>
                                   {parseInt(loginState.level) === UTILS.NORMAL ?
                                    <div className="col-lg-4 col-md-4 col-sm-10 offset-sm-1 py-1">
                                        <label htmlFor="service_area">Service Area</label>
                                        <input className="form-control" type="text" id="service_area" autoComplete="off" name="service_area" onChange={(e)=>handleChange(e)} placeholder="Service area" defaultValue={user.service_area}/>
                                    </div>:null}
                                   
                                    <div className="border-top offset-1 col-lg-10 col-md-10 row my-4 py-4">
                                        <div className="col-lg-6 col-md-6 col-sm-10">
                                            <label htmlFor="email">E-mail</label>
                                            <input className="form-control" disabled type="email" id="email" name="email" onChange={(e)=>handleChange(e)} placeholder="E-mail" defaultValue={user.email}/>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-10">
                                            <label htmlFor="password">Password</label>
                                            <input className="form-control" type="password" id="password" name="password" onChange={(e)=>handleChange(e)} placeholder="Password"/>
                                        </div>
                                    </div>
                                    <div className="border-top offset-1 col-lg-10 col-md-10 row my-4 py-4">
                                    <div className="my-2 col-md-4 col-lg-4 col-sm-10">
                                        {isLoading ? <Loading />:
                                        <input type="submit" className="form-control bg-success" id="btnSubmit" name="btnSubmit" value="SUBMIT"/>}
                                    </div>
                                    <div className="my-2 col-md-4 col-lg-4 col-sm-10">
                                        <input onClick={()=>{navigate(-1)}}
                                        type="button"
                                        className="form-control"
                                        value="Cancel"
                                        />
                                    </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </> 
        )
    }
}
export default Settings