import React, { useEffect } from "react";
import Header from "../home/header";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import UTILS from "../../utils/commons";
import Loading from "../home/Loading";

const ReceiveInventory=props=>{
  var loc = useLocation();
    const loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const storedData = JSON.parse(JSON.stringify(loc.state));

    const [isLoading,setIsLoading] = useState(false);
    const [feedback,setFeedback] = useState("");
    const [profitMargin,setProfitMargin] = useState(UTILS.PROFIT_MARGIN);
    const [supplier,setSupplier] = useState(storedData && storedData.suppliers && storedData.suppliers.length > 0 ? storedData.suppliers[0].id:null);
    const [product,setProduct] = useState(storedData && storedData.products && storedData.products.length > 0 ? storedData.products[0].id:null);

    let navigate = useNavigate();

    const saveInventory=async (inv)=>{
        var formData = new FormData();
        formData.append("product",inv.product);
        formData.append("supplier",inv.supplier);
        formData.append("invoice_no",inv.invoice_no);
        formData.append("invoice",inv.invoice);
        formData.append("cif",inv.cif);
        formData.append("clearing",inv.clearing);
        formData.append("tpri",inv.tpri);
        formData.append("quantity",inv.quantity);
        formData.append("selling_price",inv.selling_price);
        formData.append("buying_price",inv.buying_price);
        formData.append("admin",inv.admin);
        formData.append("btnReceiveInventory","ReceiveInventory");
    try{
        const res = await fetch(UTILS.URL+"?tag=inventory",{method:"POST",body:formData})
        const result = await res.json();
        setIsLoading(false)
        if(result){
          console.log("tag: ",result);
            if(parseInt(result.code) ===0){
                var d = JSON.parse(JSON.stringify(storedData));
                d.inventory = result.inventory;
                return d;
                // navigate("/inventory",{replace:true,state:d});
            }
            else{
                setFeedback(result.msg);
                return null;
            }
        }
      }catch(e){
        setFeedback(e);
        // setIsLoading(false)
        return null;
      }
        
    }
    const cleanInvoiceNumber = (invoice)=>{
      if(invoice.includes('/')){
        return invoice.replaceAll('/','-').trim();
      }
      else return invoice.trim();
    }
    const handleSubmit = async (e)=>{
        e.preventDefault();
        const form = e.target;
        setIsLoading(true);
        let file = form.invoice_file.files[0];
        let productId = product;
        let supplierId = supplier;//form.supplier[form.supplier.options.selectedIndex].value;
        
        let quantity = parseInt(form.quantity.value.replaceAll(",","").trim());
        let invoice_no = cleanInvoiceNumber(form.invoice.value);
        let cif = parseFloat(form.cif.value.replaceAll(",","").trim());
        let tpri=parseFloat(form.tpri.value.replaceAll(",","").trim());
        let clearing = parseFloat(form.clearing.value.replaceAll(",","").trim());
        let b_price = parseFloat(form.buying_price.value.replaceAll(",","").trim());
        let s_price =parseFloat(form.selling_price.value.replaceAll(",","").trim());
        let admin = loginState.id;
        let inventory = {admin:admin,product:productId,supplier:supplierId,invoice_no:invoice_no,quantity:quantity,cif:cif,tpri:tpri,clearing:clearing,buying_price:b_price,selling_price:s_price,invoice:file};
        if(supplierId == undefined || supplierId == null){
          alert('Please select a supplier');
          return;
        }
        if(productId == undefined || productId == null){
          alert('Please select a product');
          return;
        }
        const result = await saveInventory(inventory);
        setIsLoading(false);
        if(result){
          navigate('/inventory',{replace:true,state:result})
        }
        
    }
    const handleFocus=(e)=>{
        e.preventDefault();
        let t = e.target;
        t.type = "number";
    }
    const handleBlur=(e)=>{
        e.preventDefault();
        let t = e.target;
        t.type = "text";
    }
    const handleChange=e=>{
        e.preventDefault();
        const form = document.getElementById("receive_form");
        let t = e.target;
        let cif,tpri,clearing,quantity,price=0;
        let val = t.value;
        let v = val.replaceAll(",","");
        let s = UTILS.thousandSeparator(v);
        let sel = 0;
        switch(t.id){
            case "cif":
                cif = parseFloat(v);
                tpri = (form.tpri.value) ? parseFloat(form.tpri.value.replaceAll(",","")) : 0;
                clearing = (form.clearing.value) ? parseFloat(form.clearing.value.replaceAll(",","")) : 0;
                quantity = (form.quantity.value) ? parseInt(form.quantity.value.replaceAll(",","")):1;
                
                price = (cif + tpri + clearing)/quantity;
                sel = parseFloat(profitMargin * price).toFixed(2);
                form.buying_price.value = UTILS.thousandSeparator(price.toFixed(2)).toString();
                form.selling_price.value = UTILS.thousandSeparator(sel);
                t.type = "text";
                t.value = s;
                break;
            case "tpri":
                tpri = parseFloat(v);
                cif = (form.cif.value) ? parseFloat(form.cif.value.replaceAll(",","")) : 0;
                clearing = (form.clearing.value) ? parseFloat(form.clearing.value.replaceAll(",","")) : 0;
                quantity = (form.quantity.value) ? parseInt(form.quantity.value.replaceAll(",","")):1;
                price = (cif + tpri + clearing)/quantity;

                sel = parseFloat(profitMargin * price).toFixed(2);
                form.buying_price.value = UTILS.thousandSeparator(price.toFixed(2));
                form.selling_price.value = UTILS.thousandSeparator(sel);
                t.type = "text";
                t.value = s;
                
                break;
            case "clearing":
                clearing = parseFloat(v);
                tpri = (form.tpri.value) ? parseFloat(form.tpri.value.replaceAll(",","")) : 0;
                cif = (form.cif.value) ? parseFloat(form.cif.value.replaceAll(",","")) : 0;
                quantity = (form.quantity.value) ? parseInt(form.quantity.value.replaceAll(",","")):1;
                
                price = (cif + tpri + clearing)/quantity;
                sel = parseFloat(profitMargin * price).toFixed(2);
                form.buying_price.value = UTILS.thousandSeparator(price.toFixed(2));
                form.selling_price.value = UTILS.thousandSeparator(sel);
                t.type = "text";
                t.value = s;
                break;
            case "quantity":
                quantity = parseInt(v) > 0 ? parseInt(v):1;
                tpri = (form.tpri.value) ? parseFloat(form.tpri.value.replaceAll(",","")) : 0;
                clearing = (form.clearing.value) ? parseFloat(form.clearing.value.replaceAll(",","")) : 0;
                cif = (form.cif.value ) ? parseFloat(form.cif.value.replaceAll(",","")) : 0;
                price = (cif + tpri + clearing)/quantity;
                sel = parseFloat(profitMargin * price).toFixed(2);
                form.buying_price.value = UTILS.thousandSeparator(price.toFixed(2));
                form.selling_price.value = UTILS.thousandSeparator(sel);
                t.type = "text";
                t.value = s;
                break;
            case "selling_price":
                let buy_price = parseFloat(form.buying_price.value.trim().replaceAll(',','')).toFixed(0);
                let profit = parseFloat((parseInt(v))/buy_price).toFixed(2) - 1;
                form.profit_margin.value = parseFloat(profit).toFixed(1) * 100;
                t.type = 'text';
                t.value = s;
                
                break;
            case "profit_margin":
              let p = 1;
              if(val && val.length > 0&& val > 0 && val <=100){
                p = 1+(val/100);
              }
              else {
                t.value = 0;
              }
              sel = parseFloat(p * parseInt(form.buying_price.value.trim().replaceAll(",","")));
              form.selling_price.value = UTILS.thousandSeparator(sel);
              setProfitMargin(p)
              console.log(supplier)
              break;
            default:
                break;
        }
    }
    const handleSupplierChange=(e)=>{
      const supp = e.target.value;
      setSupplier(supp);
    }
    const handleProductChange=(e)=>{
      const prod = e.target.value;
      setProduct(prod);
    }
    useEffect(()=>{

    },[])
    if(loginState){
        return(<>
        <Header auth={loginState}/>
        <section className="dashboard">
            {feedback.length > 0 ? <span className="feedback col-md-8 col-lg-8 col-sm-10 py-2 danger" id="feedback">{feedback}</span>:null}
     <div className="content">
          
          <div className="col-md-10 col-lg-10 col-sm-10 offset-1">
            <span className="medium-text">Receive Inventory</span>
            <form id="receive_form"
              className="col-md-10 col-lg-10 col-sm-10 offset-1"
              method="post" onSubmit={handleSubmit}
            >
            <div className="row">
            <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
              <label htmlFor="supplier">Select Supplier</label>
              <select
              onChange={handleSupplierChange}
                value={supplier}
                className="form-control"
                id="supplier"
                name="supplier">
                    {storedData.suppliers.map((s)=>{
                        return <option key={UTILS.randomKey()} value={s.id}>{s.name}</option>
                    })}
                </select>
            
            </div>
            <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
                <label htmlFor="product">Select Product</label>
                <select
                  className="form-control"
                  id="product" value={product} onChange={handleProductChange}
                  name="product">{storedData.products.filter(p=>parseInt(p.status)===0).map(p=>{
                    return <option key={UTILS.randomKey()} value={p.id}>{p.name+" ("+p.pack_size+")"}</option>
                  })}
                </select>
              </div>
            
            </div> 
            <div className="row">
            
              <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
                
                <label htmlFor="quantity">Quantity</label>
                <input
                  type="text" required
                  className="form-control"
                  id="quantity"
                  name="quantity"
                  placeholder="Number of cartons" onChange={(e)=>handleChange(e)} onFocus={(e)=>handleFocus(e)} onBlur={(e)=>handleBlur(e)}
                />
              </div>
              <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">             
                <label htmlFor="invoice">Invoice Number</label>
              <input
                type="text" required
                className="form-control"
                id="invoice"
                name="invoice"
                placeholder="Invoice number" 
              />
            </div>
  
              </div>
              <div className="row">
                <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
                  <label htmlFor="invoice_file">Upload Invoice</label>
                  <input type="file" id="invoice_file" name="invoice_file" />
                </div>
              </div>
            
              <div className="row">
                <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
                  <label htmlFor="cif">CIF</label>
                  <input
                    type="text" required onFocus={(e)=>handleFocus(e)} onBlur={(e)=>handleBlur(e)}
                    className="form-control"
                    id="cif"
                    name="cif"
                    placeholder="CIF" onChange={(e)=>handleChange(e)}
                  />
                </div>
                <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
                  <label htmlFor="tpri">TPRI Cost</label>
                  <input
                    type="text" required onFocus={(e)=>handleFocus(e)} onBlur={(e)=>handleBlur(e)}
                    className="form-control"
                    id="tpri"
                    name="tpri"
                    placeholder="TPRI Costs" onChange={(e)=>handleChange(e)}
                  />
                </div>
            </div>
            <div className="row">
              <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
                <label htmlFor="clearing">Clearing Charges</label>
                <input
                  type="text" required onFocus={(e)=>handleFocus(e)} onBlur={(e)=>handleBlur(e)}
                  className="form-control"
                  id="clearing"
                  name="clearing"
                  placeholder="Clearing costs" onChange={(e)=>handleChange(e)}
                />
              </div>
              <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
                <label htmlFor="buying_price">Buying Price</label>
                <input
                  type="text" disabled
                  className="form-control"
                  id="buying_price"
                  name="buying_price"
                  placeholder="Buying price"
                />
              </div>
            </div>
            <div className="row">
            <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
              <label htmlFor="profit_margin">Profit Margin (%)</label>
              <input
                type="number" max={100}
                className="form-control"
                id="profit_margin"
                name="profit_margin"
                placeholder="Profit Margin" defaultValue={parseInt((profitMargin - 1)*100)} onChange={(e)=>handleChange(e)}
              />
            </div>
            <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
              <label htmlFor="selling_price">Selling Price</label>
              <input
                type="text"  onFocus={(e)=>handleFocus(e)} onBlur={(e)=>handleBlur(e)}
                className="form-control"
                id="selling_price"
                name="selling_price"
                placeholder="Selling Price" onChange={(e)=>handleChange(e)}
              />
            </div></div>
            <div className="form-group my-2 row">
              <div className="my-2 col-md-6 col-lg-6 col-sm-10">
                {isLoading ? <Loading/>:
                <input
                  type="submit"
                  className="form-control bg-success"
                  id="btnSubmit"
                  name="btnSubmit"
                  value="SUBMIT"
                />}
              </div>
              <div className="my-2 col-md-6 col-lg-6 col-sm-10">
                <input onClick={()=>{navigate(-1,{replace:true,state:storedData})}}
                  type="button"
                  className="form-control"
                  value="Cancel"
                />
              </div>
            </div>
            </form>
          </div>
        </div>
      </section>
        </>)
    }

}
export default ReceiveInventory;