import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useState } from "react";
import Header from "../home/header";
import UTILS from "../../utils/commons"
import InventoryItem from "./inventory_item";
import commons from "../../utils/commons";
import ReportHeadRow from "../reports/report_header";

const Inventory=()=>{
    let navigate = useNavigate();
    var location = useLocation();
    const loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const storedData = JSON.parse(JSON.stringify(location.state));
    let issues = storedData.issues;
    let inventory = storedData.inventory;
    let transactions = storedData.transactions;
    console.log("iv: ",inventory,location.state.inventory);
    const [data,setData] = useState([]);
    const [filteredData,setFilteredData] = useState(data)
    var heads=[{text:"SN",align:"text-left"},{text:"Product",align:"text-left"},{text:"Pack-Size",align:"text-left"},{text:"Quantity",align:"text-right"},{text:"Amount (Tsh)",align:"text-right"},{text:"Invoice",align:"text-left"}];
      
    const prepareData=()=>{
        var d;
        if(parseInt(loginState.level) === UTILS.ADMIN){
            if(issues.length > 0){
                d = inventory.map(iv=>{
                    let invNew = JSON.parse(JSON.stringify(iv));
                    var q;
                    q = issues.filter(tx=>tx.invoice_no === iv.invoice_no && iv.product.id === tx.product.id).map(i=>parseInt(i.quantity)).reduce((a,b)=>a+b,0);
                    invNew.quantity = parseInt(invNew.quantity) - q;
                return invNew;
                })
            }
            else{
                d = inventory;
            }
        }
        else{
            if(transactions.length > 0){
                d = issues.map((is,k)=>{
                    let mIssue = JSON.parse(JSON.stringify(is));
                    issues.forEach((i,m)=>{
                        if(i.product.id === is.product.id && i.rep.id === is.rep.id && i.invoice_no === is.invoice_no && m!==k){
                            mIssue.quantity = parseInt(mIssue.quantity) + parseInt(i.quantity);
                            issues.splice(k,1);
                        }
                    });
                    return mIssue;
                }).map(is=>{
                    var invNew = JSON.parse(JSON.stringify(is));
                    let q = 0;
                    transactions.forEach(tx=>{
                        if(is.rep.id === tx.rep.id && is.invoice_no === tx.invoice_no && is.product.id === tx.product_detail.id){
                            q = q+ parseInt(tx.quantity);
                        }
                    });
                    invNew.quantity = invNew.quantity - q;
                    return invNew;
                })
            }
            else{
                d = issues;
            }
        }
        setData(d);
        setFilteredData(d);
        
    }  
    const search=(e)=>{
        let keyword = e.target.value.trim().toLowerCase();
        let result = data;
        if(keyword.length > 0){
            result = data.filter(d=>d.product.name.toLowerCase().includes(keyword) ||d.product.pack_size.toLowerCase().includes(keyword) || d.invoice_no.toLowerCase().includes(keyword));
        }   
        setFilteredData(result);
    } 
    useEffect(()=>{
        prepareData(data);
    },[]);
    if(loginState){
        return(<>
        <Header auth={loginState}/>
        <section className="dashboard">
        <div className="container">
            <span className="actions-wide">
                <p className="large-text">Inventory</p>
                {parseInt(loginState.level) === UTILS.ADMIN ?
                <div className="d-flex "><button className="btn bg-success  d-flex align-items-center mx-2" onClick={()=>navigate("/inventory/receive",{replace:false,state:storedData})}><span className="material-icons">south_west</span>&nbsp;Receive</button>
                <button className="btn primary-bg d-flex align-items-center mx-2" onClick={()=>navigate("/inventory/issue",{replace:false,state:storedData})}><span className="material-icons">north_east</span>&nbsp;Issue</button></div>:null}
                
            </span>
            <input className="form-control" placeholder="search" type="text" onChange={search} />
            <div className="details-list">
                <ReportHeadRow data={heads} type="inventory"/>
                {filteredData.length > 0 ? filteredData.map((p,index)=>{
                return <InventoryItem sn={index} data={p} key={commons.randomKey()} source={filteredData}/>
            }):<span>No data</span>}
            </div>
        </div>
      </section>
        </>)
    }

}
export default Inventory;