import { Line,Bar } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
import Login from "./login_form";
import UTILS from "../../utils/commons";
import Header from "./header";
import React, { useEffect,useState } from "react";
import ButtonCard from "./button_card";
import { useNavigate } from "react-router";
Chart.register(...registerables);
const Dashboard =(props)=>{
    var navigate = useNavigate();
    var db = {payments:[],suppliers:[],products:[],users:[],transactions:[],inventory:[],customers:[],reps:[]};//storage.getItem("db");
    
    const [data,setData] = useState(db);
    const [chartData,setChartData]=useState({labels:UTILS.MONTHS,datasets:[]});
    
    var session = window.sessionStorage;
    var loginState = (session.getItem("loginState") != null) ? JSON.parse(session.getItem("loginState")):null;
    
       
    
    const prepareChartData=(year=null)=>{
      var products = [...new Set(data.transactions.map(t=>t.product_detail.id))];
      var currentYear = year;
      if(year == null) currentYear = new Date().getFullYear();
      var d = data.transactions.map(t=>{
        let tx = JSON.parse(JSON.stringify(t));
        let date = new Date(t.date_created*1000);
        tx.month = date.getMonth();
        tx.year = date.getFullYear();
        return tx;
      }).filter(tx=>tx.year === currentYear);
      
      var ds=[];
        products.map(p=>{
          return data.products.find(i=>i.id === p);
        })
        .forEach(p=>{
      let dt = [];
            UTILS.MONTHS.forEach((m,i)=>{
              let sales = d.filter(t=>t.month === i && t.product_detail.id === p.id).map(t=>parseInt(t.cost)).reduce((a,b)=>a+b,0);
              dt.push(sales);
            });
            let dts = {data:dt,label:p.name+'('+p.pack_size+')',backgroundColor:'#'+Math.floor(Math.random()*16777215).toString(16)}
            ds.push(dts);
        
        });
      var chartData = {
        labels:UTILS.MONTHS,
        datasets:ds,
        
      }
      setChartData(chartData);
    }
    const loadData =(user)=>{
      return new Promise((resolve,reject)=>{
        fetch(UTILS.URL+"?uid="+user.id+"&level="+user.level,{
          method:"GET",cache:'reload'
      }).then(res=>res.json())
      .then(result=>{
          resolve(result);
          
          
      }).catch(e=>{
        console.log(e)
        reject("Oops, something went wrong")
      })
    })
      
    }
    
    const handleAuth=(u)=>{
        session.setItem("loginState",JSON.stringify(u));
        loginState = JSON.parse(session.getItem("loginState"));
        // storedData = JSON.parse(storage.getItem("data"));
    }
    useEffect(()=>{
      if(loginState){
        loadData(loginState).then(result=>{
          console.log("result; ",result);
          data.products = result.products['products'];
          data.transactions = result.transactions['transactions'];
          data.issues = result.issues["issues"];
          data.customers = result.customers['customers'];
          data.payments = result.payments['payments'];
          if(parseInt(loginState.level) === UTILS.ADMIN){
            data.suppliers = result.suppliers['suppliers'];
            data.reps = result.reps['reps'];
            data.inventory = result.inventory['inventory'];
          }
          setData(data);
          prepareChartData();
        })
        .catch(e=>{
          console.log(e);
        })
      }
    },[]);
   
    const handleButtonCardClick=(target)=>{
      // props.history.push("/"+target);
      // window.location.pathname = "/"+target;
      navigate("/"+target,{state:data});
    }
    
    if(loginState){
        return(
          <>
            <Header auth={loginState}/>
            <section className="dashboard">
              <div className="details">
              {parseInt(loginState.level) === UTILS.ADMIN ?<>
                <ButtonCard icon='shopping_basket' text="Products" onClick={()=>handleButtonCardClick("products")}/>
                <ButtonCard icon='local_shipping' text="Suppliers" onClick={()=>handleButtonCardClick("suppliers")}/>
                <ButtonCard icon='inventory' text="Inventory" onClick={()=>handleButtonCardClick("inventory")}/>
                <ButtonCard icon='people' text="Sales Manager" onClick={()=>handleButtonCardClick("reps")}/>
                <ButtonCard icon='payment' text="Payments" onClick={()=>handleButtonCardClick("payments")}/>
                <ButtonCard icon='receipt_long' text="Transactions" onClick={()=>handleButtonCardClick("transactions")}/>
                <ButtonCard icon='assessment' text="Report" onClick={()=>handleButtonCardClick("reports")}/>
                <ButtonCard icon='settings' text="Settings" onClick={()=>handleButtonCardClick("settings")}/>
                </>:<>

                <ButtonCard icon='inventory' text="Inventory" onClick={()=>handleButtonCardClick("inventory")}/>
                <ButtonCard icon='people' text="Customers" onClick={()=>handleButtonCardClick("customers")}/>
                <ButtonCard icon='attach_money' text="Sale" onClick={()=>handleButtonCardClick("sales")}/>
                <ButtonCard icon='payment' text="Payments" onClick={()=>handleButtonCardClick("payments")}/>
                <ButtonCard icon='receipt_long' text="Transactions" onClick={()=>handleButtonCardClick("transactions")}/>
                <ButtonCard icon='assessment' text="Report" onClick={()=>handleButtonCardClick("reports")}/>
                <ButtonCard icon='settings' text="Settings" onClick={()=>handleButtonCardClick("settings")}/>
                </>}
              </div>
              <div className="graph" id="chart-area">
               {chartData ?  <Bar options={{options:{
          plugins:{
            legend:{
              display:true,position:'left'
            }
          }}
        }} data={chartData}/>: <span>No data yet</span>}
              </div>
            </section>
          </>
        )
        
    }
    else return(<Login onAuth={(user=>handleAuth(user))} target={"/dashboard"}/>)
    }
    
export default Dashboard;