import React from "react";

const SupplierItem = (props)=>{

    var p = props.data;       
    return(
        <div className="item-list">
            <div className="item-data"  onClick={(id)=>props.onClick(id)}>
                <span className="item-title">{p.name}</span>
                <span className="item-focus">{p.address+", "+p.country}</span>
            </div>
            <div className="action-buttons">
                <span className="material-icons" onClick={(id)=>props.onViewDetails(id)}>info</span>
                <span className="material-icons" onClick={(id)=>props.onEdit(id)}>edit</span>
                <span className="material-icons" onClick={(id)=>props.onDelete(id)}>delete</span>
            </div>
        </div>
    )
}
export default SupplierItem;