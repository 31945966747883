import React, { useEffect, useState } from "react";
import Header from "../home/header";
import Loading from "../home/Loading";
import Login from "../home/login_form";
import { useLocation, useNavigate } from "react-router";
import UTILS from "../../utils/commons";

const PaymentForm = props=>{
    var navigate = useNavigate();
    var location = useLocation();
    const loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    var storedData = JSON.parse(JSON.stringify(location.state));
    var paymentTypes = parseInt(loginState.level) === UTILS.ADMIN ?[{id:1,text:"Creditors Payment"},{id:2,text:"Operational Expense"}]: [{id:0,text:"Debtors Payment"},{id:2,text:"Operational Expense"}];
    const products = storedData.products;
    var myInventory = storedData.inventory.filter(iv=>parseInt(iv.status) === 0).map(iv=>{
        let inv = JSON.parse(JSON.stringify(iv));
        let a = 0;
        storedData.payments.forEach(p=>{
            if(p.reference === iv.id) a += parseInt(p.amount);
        });
        inv.cost = inv.cif - a;
        return inv;
    });
    var myTrans = storedData.transactions.filter(t=>parseInt(t.type) === 1).map(tx=>{
        let t = JSON.parse(JSON.stringify(tx));
        let a = 0;
        storedData.payments.forEach(p=>{
            if(p.reference === tx.id) a += p.amount;
        });
        t.cost = t.cost - a;
        return t;
    }).filter(t=>t.cost > 0);
    const mCustomerIds = [... new Set(myTrans.map(t=>t.customer_detail.id))];
    var customers = mCustomerIds.map(id=>{
        return myTrans.find(t=>t.customer == id).customer_detail;
        
    });
    var suppliers = [...new Set(myInventory.map(i=>i.supplier.id))].map(sid=>{
        let sup = storedData.suppliers.find(s=>s.id === sid);
        return sup;
    });
    const [isLoading,setIsLoading] = useState(false);
    const [feedback,setFeedback] = useState("");
    const [trans,setTrans] = useState(0);
    const [transactions,setTransactions] = useState(myTrans);
    const [inventory,setInventory] = useState(myInventory);
    const [amount,setAmount] = useState(0);
    const [customer,setCustomer] = useState(0);
    const [supplier,setSupplier] = useState(0);
    const [inv,setInv] = useState(0);
    const [type,setType] = useState(paymentTypes[0].id);
    const [showSubmit,setShowSubmit] = useState(customers.length >0 && products.length >0);

    const handleSubmit=e=>{
        e.preventDefault();
        setIsLoading(true);
        let form = e.target;
        let type = parseInt(form.payment_type.value);
        let account = type === 2 ? "Expenses":(parseInt(loginState.level) === UTILS.ADMIN ? supplier:customer);//form.customer.value;
        let owner = loginState.id;
        let reference = type === 2 ? "Expenses":(parseInt(loginState.level) === UTILS.ADMIN ? inv.id :trans.id);
        let uid = loginState.id;
        let amountPaid = amount;
        let payment_date = Date.parse(form.payment_date.value);
        let fileInput = form.document_file;
        let description = type === 2 ? form.description.value.trim()+" paid to "+form.paid_to.value.trim()  :(type === 0 ? "Payment from credit sale":"Payment to supplier");
        let payment = {description:description,uid:uid,type:type,account:account,owner:owner,reference:reference,payment_type:type,payment_date:payment_date,amount:amountPaid};
        
        if(fileInput.files[0]){
            payment.file = fileInput.files[0];
        }
        savePayment(payment);
        
    }
    //save transaction
    const savePayment=(payment)=>{
        var formData = new FormData();
        formData.append("account",payment.account);
        formData.append("reference",payment.reference);
        formData.append("owner",payment.owner);
        formData.append("amount",payment.amount);
        formData.append("payment_date",payment.payment_date);
        formData.append("payment_type",payment.type);
        formData.append("uid",payment.uid);
        formData.append("description",payment.description);
        if(payment.file) formData.append("file",payment.file);
        formData.append("btnRegPayment","payment");
        
        var options = {
            method:"POST",
            body:formData,
            
        }
        fetch(UTILS.URL+"?tag=payment",options)
        .then(res=>res.json())
        .then(result=>{
            console.log("res: ",result);
            if(parseInt(result.code) === 0){
                storedData.payments = result.payments;
                navigate("/payments",{replace:true,state:storedData});
            }
            else setFeedback(result.msg);
            
        })
        .catch(err=>{
            console.log("err: ",err);
            setFeedback("Something went wrong! Please try again later");
        })
        .finally(()=>{
            setIsLoading(false);
        })
    }
    
    const handleCustomerChange=(e)=>{
        if(e.target.value != 0){
            let cust = customers.find(c=>c.id === e.target.value);
            let tx = transactions.filter(f=>f.customer === cust.id);
            setCustomer(cust.id);
            setTransactions(tx);
            // if(tx.length >0){
            //     setTrans(tx[0]);
            // }
        }
        else{
            setCustomer(0);
            setTransactions([]);
            setTrans(0);
        }
        if(trans == 0 || e.target.value == 0){
            setFeedback("Please select a customer and a transaction to proceed");
            setShowSubmit(false);
        }
        else{
            setShowSubmit(true);
            setFeedback("");
        }
    }
    const populate=(tid)=>{
        if(parseInt(loginState.level) === UTILS.ADMIN){
            if(tid == 0 || supplier == 0){
                setFeedback("Please select a supplier and invoice to proceed");
                setShowSubmit(false);
            }
            else{
                setShowSubmit(true);
                setFeedback("");
            }
            
            setInv(tid);
            setAmount(inv !== 0? inv.cost:0);
        }
        else{
            if(tid == 0 || customer == 0){
            setFeedback("Please select a customer and a transaction to proceed");
            setShowSubmit(false);
            }
            else{
                setShowSubmit(true);
                setFeedback("");
            }
            
            setTrans(tid);
            setAmount(trans !== 0? trans.cost:0);
        }
        
    }
   
    const handleInvoiceChange=e=>{
        let invoice = e.target.value;
        
        if(invoice == 0){
            setFeedback("Please select a supplier and invoice to proceed");
            setShowSubmit(false);
        }
        else{
            let tx = inventory.find(t=>t.invoice_no === invoice);
            let pay = storedData.payments.filter(p=>p.reference === tx.id).map(p=>p.amount).reduce((a,b)=>a+b,0);
            tx.cost = tx.cif - pay;
            setInv(tx);
            setAmount(tx.cost);
            setShowSubmit(true);
            setFeedback("");
        }
    }
    const handleSupplierChange=e=>{
        let sid = e.target.value;
        if( sid !== 0){
            let sup = suppliers.find(c=>c.id === sid);
            let tx = myInventory.filter(f=>f.supplier.id === sid);
            setSupplier(sup.id);
            setInventory(tx);
        }
        else{
            setSupplier(0);
            setInventory([]);
            setInv(0);
        }
        if(sid == 0){
            setFeedback("Please select a supplier and an invoice to proceed");
            setShowSubmit(false);
            setInv(0);
        }
        
    }
    const handleAmountChange=e=>{
        let a = e.target.value.length > 0 ? e.target.value.trim().replaceAll(",",""):0;
        setAmount(parseInt(a));
      }
    const handleTransactionChange=e=>{
        let tid = e.target.value;
        let tx = transactions.find(t=>t.id === tid);
        let pay = storedData.payments.filter(p=>p.reference === tx.id).map(p=>p.amount).reduce((a,b)=>a+b,0);
        tx.cost = tx.cost - pay;
        setTrans(tx);
        setAmount(tx.cost);
    }
    const handleTypeChange=e=>{
        let t = parseInt(e.target.value);
        setType(t);
        if(t === 2){
            setShowSubmit(true);
        }
        else{
            if(trans === 0 || inv === 0){
                setShowSubmit(false);
            }
        }
    }
    useEffect(()=>{
        let x = parseInt(loginState.level) === UTILS.ADMIN ? inv : trans;
        populate(x);
    },[trans,customer])
    if(loginState){
        return(
        <>
            <Header auth={loginState}/>
            <section className="dashboard">
                {type !== 2 && feedback && feedback.length > 0 ? <div className="py-2 text-center danger" id="form-feedback">{feedback}</div>:null}
                <div className="content-top">      
                    <div className="col-md-10 col-lg-10 col-sm-10 offset-1 wide_form">
                        <span className="my-2 row text-center"><p className="my-2 medium-text">Record Payments</p></span>
                        <form id="payment_form" className="col-md-10 col-lg-10 col-sm-10 offset-1" method="post" onSubmit={handleSubmit}>
                            
                            <div className="row">
                                <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
                                    <label htmlFor="payment_type">Payment Type</label>
                                    <select className="form-control" id="payment_type" name="payment_type" value={type} onChange={(e)=>{handleTypeChange(e)}}>
                                       {paymentTypes.map(pt=>{
                                        return <option key={UTILS.randomKey()} value={pt.id}>{pt.text}</option>
                                       })}
                                    </select>
                                </div>
                                <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
                                    <label htmlFor="payment_date">Date of Payment <em className="text-danger">*</em></label>
                                    <input type="date" className="form-control" id="payment_date" name="payment_date" required/>
                                    
                                </div>
                            </div>
                            {type === 0?
                            <div className="row">
                                    <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
                                    <label htmlFor="customer">Customer</label>
                                    <select className="form-control" id="customer" name="customer" value={customer} onChange={(e)=>{handleCustomerChange(e)}}>
                                        <option value="0">--select customer--</option>
                                        {customers.map(c=>{
                                            return <option key={UTILS.randomKey()} value={c.id}>{c.name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
                                    <label htmlFor="transaction">Transaction</label>
                                    <select className="form-control" id="transaction" name="transaction" value={trans.id} onChange={(e)=>{handleTransactionChange(e)}}>
                                        <option value="0">--select transaction--</option>
                                        {transactions.map(c=>{
                                            return <option key={UTILS.randomKey()} value={c.id}>{UTILS.formatDate(c.date_created *1000)+", "+c.quantity+" cartons of "+c.product_detail.name}</option>
                                        })}
                                    </select>
                                </div>
                                
                                </div>:
                                (type === 1 ? 
                                <div className="row">
                                    <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
                                    <label htmlFor="supplier">Supplier <em className="text-danger">*</em></label>
                                    <select className="form-control" id="supplier" name="supplier" value={supplier} onChange={(e)=>{handleSupplierChange(e)}}>
                                        <option value="0">--select supplier--</option>
                                        {suppliers.map(c=>{
                                            return <option key={UTILS.randomKey()} value={c.id}>{c.name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
                                    <label htmlFor="invoice">Invoice <em className="text-danger">*</em></label>
                                    <select className="form-control" id="invoice" name="invoice" value={inv.invoice_no} onChange={(e)=>{handleInvoiceChange(e)}}>
                                        <option value="0">--select invoice--</option>
                                        {inventory.map(c=>{
                                            return <option key={UTILS.randomKey()} value={c.invoice_no}>{c.invoice_no}</option>
                                        })}
                                    </select>
                                </div>
                                
                                </div>:
                                <div className="row">
                                    <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
                                    <label htmlFor="paid_to">Paid to <em className="text-danger">*</em></label>
                                    <input required type="text" className="form-control" id="paid_to" name="paid_to" />
                                        
                                    </div>
                                    <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
                                        <label htmlFor="description">Being payment for <em className="text-danger">*</em></label>
                                        <input required type="text" className="form-control" id="description" name="description"/>
                                        
                                    </div>
                                </div>)
                                }
                            
                            {type === 0?<div className="row">
                                <div className="form-group my-2 my-2 col-md-6 col-lg-6 col-sm-10"> 
                                    <label htmlFor="quantity">Quantity (cartons)</label>                        
                                    <input type="number" className="form-control" id="quantity" name="quantity" min="1" placeholder="No. of cartons" readOnly defaultValue={trans.quantity}
                                    />
                                </div>
                                <div className="form-group my-2 my-2 col-md-6 col-lg-6 col-sm-10"> 
                                    <label htmlFor="price">Price</label>                        
                                    <input type="number" className="form-control" id="price" name="price" placeholder="Price" defaultValue={trans.price} readOnly/>
                                    
                                </div>
                            </div>:null}
                            <div className="row"> 
                                <div className="form-group my-2 my-2 my-2 col-md-6 col-lg-6 col-sm-10">
                                    <label htmlFor="amount">Amount {supplier === 0 ?"":"(CIF)"} <em className="text-danger">*</em></label>
                                    <input required type="text" className="form-control" id="amount"  name="amount" value={UTILS.thousandSeparator(amount)} onChange={(e)=>handleAmountChange(e)}/>
                                </div>
                                <div className="form-group my-2 my-2 my-2 col-md-6 col-lg-6 col-sm-10">
                                    <label htmlFor="document_file">Supporting Document <em className="text-danger">*</em></label>                        
                                    <input required type="file" className="form-control" id="document_file" name="document_file"/>
                                </div>
                            </div>
                            <div className="form-group my-2 row">
                                <div className="my-2 col-md-6 col-lg-6 col-sm-10">
                                    {isLoading ? <Loading />:(showSubmit ?
                                    <input type="submit" className="form-control bg-success" id="btnSubmit" name="btnSubmit" value="SUBMIT"/>:null)}
                                </div>
                                <div className="my-2 col-md-6 col-lg-6 col-sm-10">
                                    <input onClick={()=>{navigate(-1,{replace:true,state:storedData})}}
                                    type="button"
                                    className="form-control"
                                    value="Cancel"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>)
    }
    return <Login target='/payments'/>
    
}
export default PaymentForm;