import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Header from "../home/header";
import Login from "../home/login_form";
import SupplierItem from "./supplier_item";
import UTILS from "../../utils/commons";

const SupplierList = props=>{
    let location = useLocation();
    const loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const storedData = location.state;
    var navigate = useNavigate();

    const [suppliers,setSuppliers] = useState([]);
    const [feedback,setFeedback] = useState("");
    
    var data = storedData.suppliers.filter(s=>parseInt(s.status) === 0);
    const getSuppliers=()=>{
        setSuppliers(data);
    }
    const confirmDelete = (itemId)=>{
        let message = "Are you sure you want to delete this supplier?";
        if(window.confirm(message)){
        
            deleteSupplier(itemId).then(result=>{
                if(parseInt(result.code) === 0){
                    storedData.suppliers = result.suppliers;
                    setSuppliers(storedData.suppliers.filter(p=>parseInt(p.status) === 0));

                }
                else{
                    setFeedback(result.msg);
                }
                
            })
        }
        
    }
    const deleteSupplier=(id)=>{
        let supplier = storedData.suppliers.find(p=>p.id === id);
        console.log("sup: ",supplier);
        return new Promise((resolve,reject)=>{
            let url = UTILS.URL +"?tag=supplier";
            var formData = new FormData();
            formData.append("status",1);
            formData.append("user_id",supplier.admin);
            formData.append("btnDeleteSupplier","delete");
            formData.append("id",supplier.id);
            var options = {body:formData,method:"POST"};
            fetch(url,options).then(res=>res.json()).then(result=>{
                resolve(result);
            }).catch(e=>reject(e));
        })
    }
    useEffect(()=>{
        getSuppliers(suppliers);
    },[]);
    if(loginState){
        return(
        <>
            <Header auth={loginState}/>
            <section className="dashboard">
                <div className="container">
                <span className="actions-wide">
                    <p className="large-text">Suppliers</p>
                    <button className="btn bg-success" onClick={()=>{navigate("/suppliers/new",{replace:true,state:storedData})}}>Add</button>
                </span>
                {feedback && feedback.length > 0?<span className="feedback col-md-8 col-lg-8 col-sm-10 py-2 danger" id="feedback"></span>:null}
                <div className="details-list" id="list_container">  
                {suppliers.map(s=>{
                    return <SupplierItem data={s} key={s.id} onClick={()=>{navigate('/suppliers/'+s.id,{replace:true,state:storedData})}} onEdit={()=>{navigate("/suppliers/edit/"+s.id,{replace:true,state:storedData})}} onDelete={()=>confirmDelete(s.id)}/>
                })}
                </div></div>
            </section>
        </> 

    )
    }
    <Login target="/suppliers" />
}
export default SupplierList;