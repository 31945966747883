import React from "react";
import {useNavigate } from "react-router";
import UTILS from '../../utils/commons';


const PaymentItem = props=>{
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    var navigate = useNavigate();
    var storedData = JSON.parse(JSON.stringify(props.source));
    var d = props.data;
    console.log("x: ",d);
    var group;
    var sum = 0;
    if(props.isGrouped){
        switch(props.group){
            case "rep":
                group = d[0].rep.fname+ " "+d[0].rep.lname;
                break;
            case "product":
                group = d[0].product_detail.name+ ' ('+d[0].product_detail.pack_size+")";
                break;
                case "account":
                    group = d[0].account.name;
                    break;
            case "invoice":
                group = "Invoice no: "+d[0].invoice_no;
                break;
            case "date":
                group = UTILS.getMonth(parseInt(d[0].month))+" "+d[0].year;
                break;
            default:
                break;
        }
        d = d.map(x=>{
            let k = x;
            k.ref = parseInt(x.payment_type) === 0 ? storedData.transactions.find(t=>t.id === x.reference):storedData.inventory.find(i=>i.id === x.reference);
            k.date = UTILS.formatDate(parseInt(x.payment_date));
            k.amount = parseInt(x.amount);
            // k.ref = parseInt(x.payment_type) === 0 ? storedData.transactions.find(t=>t.id === x.reference):storedData.inventory.find(i=>i.id === x.reference);
            return k;
        });
        
        sum = d.map(x=>x.amount).reduce((a,b)=>a+b);
    }
    else{
        d.date = UTILS.formatDate(parseInt(d.payment_date));
        d.ref = parseInt(d.payment_type) === 0 ? storedData.transactions.find(t=>t.id === d.reference):storedData.inventory.find(i=>i.id === d.reference);
    }
    console.log("ddd; ",d);
    return(<>
        {props.isGrouped ? <div className="row my-2 col-lg-12 col-md-12 item-list bg-success">
            <div className="col-lg-2 col-md-2 col-sm-12">{group}</div>
            <div className="col-lg-2 col-md-2 col-sm-12 text-left">{}</div>
            <div className="col-lg-2 col-md-2 col-sm-12 text-left">{}</div>
            <div className="col-lg-2 col-md-2 col-sm-12 text-right">{UTILS.thousandSeparator(sum)}</div>
            <div className="col-lg-2 col-md-2 col-sm-12 text-left">{}</div>
            
            </div>:null}
            {props.isGrouped ? d.map(x=>{
            return <div key={UTILS.randomKey()} className={"row my-2 col-lg-12 col-md-12 item-list "+(parseInt(d.payment_type)===0 ? "flag-ok":"flag-red")} >
        <div className="col-md-2 col-lg-2 col-sm-12 bold text-left">{x.date}</div>
        {parseInt(d.payment_type) === 2 ? 
        <><div className="col-md-2 col-lg-2 col-sm-12 bold text-left">{x.account}</div>
        <div className="col-md-2 col-lg-2 col-sm-12 bold text-left">{x.reference}</div></>:
        <>
        <div className="col-md-2 col-lg-2 col-sm-12 bold text-left text-primary" onClick={()=>{navigate((parseInt(x.payment_type) === 0 ? "/customers/":'/suppliers/')+x.account.id,{replace:false,state:storedData})}}>{x.account.name}</div>
        <div className="col-md-2 col-lg-2 col-sm-12 bold text-left text-primary" onClick={()=>{navigate((parseInt(x.payment_type) === 0?"/transactions/":"/inventory/")+x.reference,{replace:false,state:storedData})}}>{x.ref ?x.ref.invoice_no:x.reference}</div>
        </>}
        <div className="col-md-2 col-lg-2 col-sm-12 bold text-right">{UTILS.thousandSeparator(x.amount)}</div>
        
        <div className="col-md-2 col-lg-2 col-sm-12 bold text-left">
            {x.file ? <a href={UTILS.IMG_URL+"/"+x.file} target="_blank" rel="noreferrer">view file</a>:'No Proof'}
        </div>
        <div className="col-md-2 col-lg-2 col-sm-12 bold text-left">{x.owner.fname +' '+x.owner.lname}</div>
        {/* <div className="col-md-1 col-lg-1 col-sm-12 bold text-left">{x.description}</div> */}
    </div>}):
    <div className={"my-2 col-lg-12 col-md-12 item-list "+(parseInt(d.payment_type)===0 ? "flag-ok":"flag-red")} >
        <div className="col-md-2 col-lg-2 col-sm-12 bold text-left">{d.date}</div>
        {parseInt(d.payment_type) === 2 ? 
        <><div className="col-md-2 col-lg-2 col-sm-12 bold text-left">{d.account}</div>
        <div className="col-md-2 col-lg-2 col-sm-12 bold text-left">{d.reference}</div></>:
        <>
        <div className="col-md-2 col-lg-2 col-sm-12 bold text-left text-primary" onClick={()=>{navigate((parseInt(d.payment_type) === 0 ? "/customers/":'/suppliers/')+d.account.id,{replace:false,state:storedData})}}>{d.account.name}</div>
        <div className="col-md-2 col-lg-2 col-sm-12 bold text-left text-primary" onClick={()=>{navigate((parseInt(d.payment_type) === 0?"/transactions/":"/inventory/")+d.reference,{replace:false,state:storedData})}}>{d.ref ? d.ref.invoice_no:d.reference}</div>
        </>}
        <div className="col-md-2 col-lg-2 col-sm-12 bold text-right">{UTILS.thousandSeparator(d.amount)}</div>
        
        <div className="col-md-2 col-lg-2 col-sm-12 bold text-left">
            {d.file ? <a href={UTILS.IMG_URL+"/"+d.file} target="_blank" rel="noreferrer">view file</a>:'No Proof'}
        </div>
        <div className="col-md-2 col-lg-2 col-sm-12 bold text-left">{d.owner.fname +' '+d.owner.lname}</div>
        {/* <div className="col-md-1 col-lg-1 col-sm-12 bold text-left">{d.description}</div> */}
        
    </div>}</>)
}
export default PaymentItem;