import React, { useEffect, useState } from "react";
import Header from "../home/header";
import Loading from "../home/Loading";
import Login from "../home/login_form";
import { useLocation, useNavigate } from "react-router";
import UTILS from "../../utils/commons";

const SaleForm = props =>{
    var navigate = useNavigate();
    var location = useLocation();
    const loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const storedData = JSON.parse(JSON.stringify(location.state));
    const products = storedData.products;
    const customers = storedData.customers.filter(c=>parseInt(c.status) === 0);
    var issues = storedData.issues;
      if(storedData.transactions.length > 0){
        issues = storedData.issues.map(iv=>{
          let d = JSON.parse(JSON.stringify(iv));
          storedData.transactions.forEach(e => {
            if(e.product.id === iv.product.id && e.invoice_no === iv.invoice_no){
              d.quantity = d.quantity - e.quantity;
            }
          });
          return d;
        })
      }
    var uniqProducts = [...new Set(products.map(p=>p.id))].map(p=>{
        var k;
        products.forEach(pd=>{
            if(pd.id === p) k = pd;
        });
        return k;
    });
    
    const [isLoading,setIsLoading] = useState(false);
    const [feedback,setFeedback] = useState("");
    const [invoices,setInvoices] = useState([]);
    const [qtty,setQtty] = useState(0);
    const [prod,setProd] = useState(0);
    const [price,setPrice] = useState(0);
    const [amount,setAmount] = useState(qtty*price);
    const [max,setMax] = useState(0);
    const [inv,setInv] = useState("");
    const [customer,setCustomer] = useState(0);
    const [showSubmit,setShowSubmit] = useState(customers.length >0 && products.length >0);

    const handleSubmit=e=>{
        e.preventDefault();
        setIsLoading(true);
        let form = e.target;
        let type = parseInt(form.transaction_type.value);
        let customer = form.customer.value;
        let product = form.product.value;
        let invoice = form.invoice.value;
        let quantity = parseInt(form.quantity.value);
        let price = form.price.value;
        let amount = price * quantity;
        let fileInput = form.document_file;

        let transaction = {type:type,customer:customer,product:product,invoice_no:invoice,quantity:quantity,price:price,amount:amount};
        transaction.rep = loginState.detail.id;
        if(fileInput.files[0]){
            transaction.file = fileInput.files[0];
        }
        if(transaction.product == 0 || transaction.customer == 0 || transaction.invoice_no == "" || transaction.quantity == 0){
            setFeedback("Please fill in the missing information");
            setIsLoading(false);
        }
        else saveTransaction(transaction);
        
    }
    //save transaction
    const saveTransaction=(transaction)=>{
        var formData = new FormData();
        formData.append("product",transaction.product);
        formData.append("rep",transaction.rep);
        formData.append("invoice_no",transaction.invoice_no);
        formData.append("quantity",transaction.quantity);
        formData.append("cost",transaction.amount);
        formData.append("customer",transaction.customer);
        formData.append("price",transaction.price);
        formData.append("type",transaction.type);
        formData.append("admin",loginState.detail.admin);
        if(transaction.file) formData.append("file",transaction.file);
        formData.append("btnTransaction","transaction");
    
        
        var options = {
            method:"POST",
            body:formData,
            
        }
        fetch(UTILS.URL+"?tag=transaction",options)
        .then(res=>res.json())
        .then(result=>{
            if(parseInt(result.code) === 0){
                storedData.transactions = result.transactions;
                navigate("/dashboard",{replace:true,state:storedData});
            }
            else setFeedback(result.code,result.msg);
            
        })
        .catch(err=>{
            console.log("err: ",err);
            setFeedback("Something went wrong! Please try again later");
        })
        .finally(()=>{
            setIsLoading(false);
        })
    }
    
    
    const handleCustomerChange=(e)=>{
        var cust = customers.find(c=>c.id === e.target.value);
        setCustomer(cust.id);
        if(prod == 0 || e.target.value == 0){
            setFeedback("Please select a product and customer");
            setShowSubmit(false);
        }
        else{
            setShowSubmit(true);
            setFeedback("");
        }
    }
    const populate=(pid)=>{
        if(pid == 0 || customer == 0){
            setFeedback("Please select a product and customer");
            setShowSubmit(false);
        }
        else{
            setShowSubmit(true);
            setFeedback("");
        }
        var invs = issues.filter(iv=>iv.product.id === pid).map(iv=>iv.invoice_no);
        var trans= issues.map(i=>{
            let is = JSON.parse(JSON.stringify(i));
            storedData.transactions.forEach(tx=>{
                if(i.product.id === tx.product && i.invoice_no === tx.invoice_no) is.quantity = is.quantity - tx.quantity;
            });
            return is;
        });
          let def = trans.find(iv=>iv.product.id == pid && iv.invoice_no == invs[0]);
          setInvoices(invs);
          setProd(pid);
          setInv(invs.length>0 ?invs[0]:"");
          setQtty(def ?def.quantity:0);
          setMax(def ?def.quantity:0);
          setPrice(def ?def.price:0);
          setAmount(def ?def.price*def.quantity:0);
      }
      const handleProductChange=(e)=>{
          let id = e.target.value;
          populate(id);
      }
  
      const handleInvoiceChange=e=>{
          let inv_no = e.target.value;
          let inv = issues.find(iv=>iv.invoice_no === inv_no && iv.product.id === prod);
          console.log(inv);
          console.log(storedData.transactions);
          let sold = storedData.transactions.filter(tx=>tx.rep.id == inv.rep.id && tx.invoice_no == inv_no).map(tx=>parseInt(tx.quantity)).reduce((a,b)=>a+b,0);
          let max = inv.quantity - sold;
          setQtty(max);
          setInv(inv_no);
          setPrice(inv.price);
          setMax(max)
          setAmount(inv.price*max);
          document.getElementById('quantity').value = max;
      }
      const handleQttyChange=e=>{
        let q = (e.target.value) ? parseInt(e.target.value.trim().replace(/^0/)):0;
        var a = 0;
        if(q > max){
        //   e.target.setCustomValidity("There is no enough inventory "+q+"/"+max);
        e.target.value = max;  
        e.target.focus();
            
        }
        else {
            setQtty(q);
        a = q ?price * q:0;

        setAmount(a);
        }
      }
   
    const handlePriceChange=e=>{
        let p = parseInt(e.target.value);
        setPrice(p);
        let a = p*qtty;
        setAmount(a);
      }

    useEffect(()=>{
        populate(prod);
    },[prod])
    if(loginState){
        return(
        <>
            <Header auth={loginState}/>
            <section className="dashboard">
                {feedback.length > 0 ? <div className="alert text-center danger" id="form-feedback">{feedback}</div>:null}
                <div className="content-full">      
                    <div className="col-md-10 col-lg-10 col-sm-10 offset-1 wide_form">
                        <span className="medium-text col-lg-10 col-md-10 text-center">Sales Transaction</span>
                        <form id="sale_form" className="col-md-10 col-lg-10 col-sm-10 offset-1" method="post" onSubmit={handleSubmit}>
                            
                            <div className="row">
                                <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
                                    <label htmlFor="transaction_type">Transaction Type</label>
                                    <select className="form-control" id="transaction_type" name="transaction_type">
                                        <option value="0">Cash Sale</option>
                                        <option value="1">Credit Sale</option>
                                    </select>
                                </div>
                                <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
                                    <label htmlFor="customer">Customer</label>
                                    <select className="form-control" id="customer" name="customer" value={customer} onChange={(e)=>handleCustomerChange(e)}>
                                        <option value="0">--select customer--</option>
                                        {customers.map(c=>{
                                            return <option key={UTILS.randomKey()} value={c.id}>{c.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
                                    <label htmlFor="product">Product</label>
                                    <select className="form-control" id="product" name="product" value={prod} onChange={(e)=>handleProductChange(e)}>
                                        <option value="0">--select product--</option>
                                        {uniqProducts.map(p=>{
                                            return <option key={UTILS.randomKey()} value={p.id}>{p.name +" ("+p.pack_size+")"}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
                                    <label htmlFor="invoice">Invoice Number</label>
                                    <select className="form-control" id="invoice" name="invoice" value={inv} onChange={(e)=>handleInvoiceChange(e)}>
                                        {invoices.map(iv=>{
                                            return <option key={UTILS.randomKey()}>{iv}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group my-2 my-2 col-md-6 col-lg-6 col-sm-10"> 
                                    <label htmlFor="quantity">Quantity (cartons)</label>                        
                                    <input type="number" className="form-control" id="quantity" name="quantity" min="1" placeholder="No. of cartons" onChange={handleQttyChange} defaultValue={qtty}
                                    />
                                </div>
                                <div className="form-group my-2 my-2 col-md-6 col-lg-6 col-sm-10"> 
                                    <label htmlFor="price">Price</label>                        
                                    <input type="number" className="form-control" id="price" name="price" placeholder="Price" value={price} disabled
                                    />
                                </div>
                            </div>
                            <div className="row"> 
                                <div className="form-group my-2 my-2 my-2 col-md-6 col-lg-6 col-sm-10">
                                    <label htmlFor="amount">Amount</label>
                                    <input type="text" disabled className="form-control" id="amount"  name="amount" value={UTILS.thousandSeparator(amount)}/>
                                </div>
                                <div className="form-group my-2 my-2 my-2 col-md-6 col-lg-6 col-sm-10">
                                    <label htmlFor="document_file">Supporting Document</label>                        
                                    <input type="file" className="form-control" id="document_file" name="document_file"/>
                                </div>
                            </div>
                            <div className="form-group my-2 row">
                                <div className="my-2 col-md-6 col-lg-6 col-sm-10">
                                    {isLoading ? <Loading />:(showSubmit ?
                                    <input type="submit" className="form-control bg-success" id="btnSubmit" name="btnSubmit" value="SUBMIT"/>:null)}
                                </div>
                                <div className="my-2 col-md-6 col-lg-6 col-sm-10">
                                    <input onClick={()=>{navigate(-1,{replace:true,state:storedData})}}
                                    type="button"
                                    className="form-control"
                                    value="Cancel"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>)
    }
    return <Login target='/sales'/>
    
}
export default SaleForm;