import React, { useState } from "react";
import logo from "../../img/logo.png";
import Loading from "./Loading";
import UTILS from '../../utils/commons';

const PasswordResetForm=(props)=>{
    
    const [feedback,setFeedback] = useState("");
    const [isLoading,setIsLoading] = useState();

    const handleSubmit=e=>{
        e.preventDefault();
        setIsLoading(true);
        var form = e.target;
        var email = form.email.value.trim();
        var formData = new FormData();
        formData.append("email",email);
        formData.append("btnReset","reset");
        fetch(UTILS.URL+"?tag=reset",{method:"POST",body:formData})
        .then(res=>res.json())
        .then(result=>{
            console.log("res; ",result);
            setFeedback(result.msg);

        }).catch(e=>{
            console.error(e);
            setFeedback("Oops! Something went wrong");
        }).finally(()=>{
            setIsLoading(false);
        })
    }
    
    return(<>
        <section className="content-full">
          <div className="logo">
            <img src={logo} alt="Rainbow logo" />
            <p className="medium-text">Distribution Management System</p>
          </div>
          <div className="row primary-bg">
            {feedback.length > 0 ?<div className="feedback col-md-8 col-lg-8 col-sm-10 py-2" id="feedback">{feedback}</div>:null}
           
            <form id="loginform"
              className="col-md-6 col-lg-6 col-sm-10"
              method="post" onSubmit={handleSubmit}
            >
              <span className="large-text text-center">Password Reset</span>
              <div className="form-group my-2">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  required
                  placeholder="E-mail"
                />
              </div>
    
              
              <div className="form-group my-2">
                {isLoading ? <Loading />:
                <input
                  type="submit"
                  className="form-control bg-success"
                  id="btnlogin"
                  name="btnlogin"
                  value="RESET"
                />}
              </div>
              <div className="form-group my-2">
                <a className="link-button" href="/signin">Back to login</a>
              </div>
            </form>
          </div>
        </section>
        </>
    
);
}
export default PasswordResetForm;