import React from "react";

const ButtonCard=(props)=>{

    const handleClick=(e)=>{
        props.onClick();
    }
    return(<div className="icon-button" onClick={()=>handleClick()}>
    <span className="material-icons">{props.icon}</span>
    <p className="text-center">{props.text}</p>
  </div>)
}
export default ButtonCard;