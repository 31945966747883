import React, { useState } from "react";
import logo from "../../logo.png";
import UTILS from '../../utils/commons';
import Loading from "./Loading";
import { useNavigate } from "react-router";
const SignupForm=(props)=>{
 var navigate = useNavigate();
  const [isLoading,setIsLoading] = useState(false);
  const [feedback,setFeedback] = useState("");

  const handleSubmit=e=>{
    e.preventDefault();
    setIsLoading(true);
    var form = e.target;
    let fname = form.fname.value.trim();
    let lname = form.lname.value.trim();
    let email = form.email.value.trim();
    let password = form.password.value;


    let fd = {fname:fname,lname:lname,email:email,password:password,btnSignup:"signup"};
    fetch(UTILS.URL,{body:JSON.stringify(fd),method:"POST",headers:{'Content-type':'application/json'}})
    .then(res=>res.json())
    .then(result=>{
      if(parseInt(result.code) === 0 ){
        window.sessionStorage.setItem('loginState',JSON.stringify(result.user));
        navigate("/dashboard",{replace:true});
      }
    })
    .catch(e=>{
      console.log("erro: ",e);
      setFeedback("Oops! Something went wrong, please try again later");
    })
    .finally(()=>{
      setIsLoading(false);
    })
  }

    return(
    <section className="content-full">
    <div className="logo">
      <img src={logo} alt="Rainbow logo" />
      <p className="medium-text">Distribution Management System</p>
    </div>
    <div className="row primary-bg">
        {feedback.length > 0 ?<div className="feedback col-md-8 col-lg-8 col-sm-10 py-2 " id="feedback">{feedback}</div>:null}
      <form id="signupform" method="POST" className="col-md-6 col-lg-6 col-sm-10" onSubmit={handleSubmit}
      >
      <div className="form-group my-2">
          <input
            type="text"
            className="form-control"
            id="fname"
            name="fname"
            placeholder="First name" required autoCapitalize="true"
          />
        </div>
        <div className="form-group my-2">
          <input
            type="text"
            className="form-control"
            id="lname"
            name="lname"
            placeholder="Last name" required autoCapitalize="true"
          />
        </div>
        <div className="form-group my-2">
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder="E-mail" required
          />
        </div>

        <div className="form-group my-2">
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            placeholder="Password" required
          />
        </div>
        <div className="form-group my-2">
          <input
            type="password"
            className="form-control"
            id="cpassword"
            name="cpassword"
            placeholder="Confirm password" required
          />
        </div>
       <div className="form-group my-2 error hidden" id="error"></div>
        <div className="form-group my-2">
          {isLoading ? <Loading/>:
          <input
            type="submit"
            className="form-control bg-success"
            id="btnSignup"
            name="btnSignup"
            value="Sign up"
          />}
        </div>
        <div className="form-group my-2">
          <span>Already registered? &nbsp;<a className="link-button" href="/signin">Sign in</a></span>
        </div>
      </form>
    </div>
  </section>);
  }
 export default SignupForm;