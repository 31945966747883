import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Header from "../home/header";
import UTILS from "../../utils/commons"
import commons from "../../utils/commons";

const InventoryDetail=props=>{
    var location = useLocation();
    var navigate = useNavigate();
    var storedData = JSON.parse(JSON.stringify(location.state));
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    var {id} = useParams();
    var detail = storedData.inventory.find(i=>i.id === id);
    var payment = storedData.payments.filter(p=>parseInt(p.payment_type) === 1 && p.reference == detail.id).map(p=>parseInt(p.amount)).reduce((a,b)=>a+b,0)
    if(loginState){
        return(<>
        <Header auth={loginState}/>
        <section className="box">
            <div className="action-buttons"><span className="material-icons" onClick={()=>{navigate(-1,{replace:true,state:storedData})}}>close</span></div>
            <div className="medium-text">Invoice Details</div>
            <div className="content-top my-2">
                <div className="product-details">
                    <div className="my-2">
                        <label>Invoice No:</label>
                        <span><span>{detail.invoice_no}</span>&nbsp;&nbsp;{detail.invoice ? (<a href={UTILS.IMG_URL+"/"+detail.invoice} target="_blank" rel="noreferrer">view invoice</a>):'(No invoice file)'}</span>
                    </div>
                    <div className="my-2">
                        <label>Date:</label>
                        <span>{commons.formatDate(detail.date_created*1000)}</span>
                    </div>
                    <div className="my-2">
                        <label>Supplier:</label>
                        <span onClick={()=>{navigate("/suppliers/"+detail.supplier.id,{replace:false,state:storedData})}}>{detail.supplier.name}</span>
                    </div>
                    <div className="my-2">
                        <label>Product:</label>
                        <span>{detail.product.name +", "+detail.product.pack_size}</span>
                    </div>
                    <div className="my-2">
                        <label>Product Pack-Size:</label>
                        <span>{detail.product.pack_size}</span>
                    </div>
                    <div className="my-2">
                        <label>Quantity:</label>
                        <span>{commons.thousandSeparator(detail.quantity)}</span>
                    </div>
                    
                </div>
                <div className="product-details">
                    <div className="my-2">
                        <label>CIF:</label>
                        <span>{commons.thousandSeparator(detail.cif)}</span>
                    </div>
                    <div className="my-2">
                        <label>TPRI Fees:</label>
                        <span>{commons.thousandSeparator(detail.tpri)}</span>
                    </div>
                    <div className="my-2">
                        <label>Clearing Charges:</label>
                        <span>{commons.thousandSeparator(detail.clearing)}</span>
                    </div>
                    <div className="my-2">
                        <label>Amount Paid to Supplier (Tsh):</label>
                        <span>{commons.thousandSeparator(payment)}</span>
                    </div>
                    <div className="my-2">
                        <label>Amount Due to Supplier (Tsh):</label>
                        <span>{commons.thousandSeparator(detail.cif - payment)}</span>
                    </div>
                    <div className="my-2">
                        <span className="clickable" onClick={()=>{navigate("/payments/new",{replace:true,state:storedData})}}>Make Payment</span>
                    </div>
                </div>
            </div>
      </section>
        </>)
    }

}
export default InventoryDetail;