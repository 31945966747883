import React, {useEffect, useState } from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import Header from "../home/header";
import ProductItem from "./product_item";
import UTILS from "../../utils/commons";
import Login from "../home/login_form";

const ProductList=(props)=>{
    var location = useLocation();
    const loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const storedData = location.state;
    const [products,setProducts] = useState([]);
    var data = storedData.products.filter(p=>parseInt(p.status)===0);
    const getProducts=()=>{
        setProducts(data);
    }
    useEffect(()=>{
        getProducts(products);
    },[]);
    let navigate = useNavigate();
    const confirmDelete = (itemId)=>{
        let message = "Are you sure you want to delete this product?";
        if(window.confirm(message)){
        
            deleteProduct(itemId).then(result=>{
                if(parseInt(result.code) === 0){
                    storedData.products = result.products;
                    setProducts(storedData.products.filter(p=>parseInt(p.status) === 0));
                }
                
            })
        }
        
    }
    
    const deleteProduct=(pid)=>{
        let product = storedData.products.find(p=>p.id === pid);
        return new Promise((resolve,reject)=>{
            let url = UTILS.URL +"?tag=product";
            var formData = new FormData();
            formData.append("status",1);
            formData.append("user_id",product.user);
            formData.append("btnDeleteProduct","delete");
            formData.append("id",product.id);
            var options = {body:formData,method:"POST"};
            fetch(url,options).then(res=>res.json()).then(result=>{
                console.log("teest: ",result);
                resolve(result);
            }).catch(e=>reject(e));
        })
    }
    if(loginState){
    return(<>
        <Header auth={loginState}/>
        <section className="dashboard">
    <div className="container">
     <span className="actions-wide">
        <p className="large-text">Products</p>
        <button className="btn bg-success" onClick={()=>navigate("/products/new",{replace:true,state:storedData})}>Add</button>
    </span>
     <div className="details-list" id="list_container">
       
       {products.length > 0 ? (
        products.map(p=>{
            return <ProductItem data={p} key={p.id} onDelete={()=>confirmDelete(p.id)} onClick={()=>{navigate("/products/"+p.id,{replace:true,state:storedData})}} onEdit={()=>{navigate('/products/edit/'+p.id,{replace:true,state:storedData})}}/>
        })
       ):"No data"}
    </div></div>
    </section>
    </>)
    }
    else{
        return <Login target="/products"/>
    }
}
export default ProductList;