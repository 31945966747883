import React, { useEffect, useState } from "react";
import Header from "../home/header";
import UTILS from "../../utils/commons";
import { useLocation, useNavigate } from "react-router";
import Loading from "../home/Loading";

const IssueInventory=props=>{
    let navigate = useNavigate();
    var loc = useLocation();
    var storedData = JSON.parse(JSON.stringify(loc.state));
    const loginState = JSON.parse(window.sessionStorage.getItem("loginState"));

    const reps = storedData.reps.filter(r=>parseInt(r.status) === 0);
    const products = storedData.inventory.filter(f=>f.quantity > 0).map(p=>p.product);

    const [invoices,setInvoices] = useState([]);
    const [prod,setProd] = useState(products.length > 0?products[0].id:0);
    const [rep,setRep] = useState(reps.length > 0?reps[0].id:0);
    const [qtty,setQtty] = useState(0);
    const [max,setMax] = useState(0);
    const [inv,setInv] = useState("");
    const [feedback,setFeedback] = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const [showSubmit,setShowSubmit] = useState(false);

    var inventories = storedData.inventory;
      if(storedData.issues.length > 0){
        inventories = storedData.inventory.map(iv=>{
          let d = JSON.parse(JSON.stringify(iv));
          storedData.issues.forEach(e => {
            if(e.product.id === iv.product.id && e.invoice_no === iv.invoice_no){
              d.quantity = d.quantity - e.quantity;
            }
          });
          return d;
        })
      }
    const handleSubmit=e=>{
        e.preventDefault();
        setIsLoading(true);
        const form = e.target;
        let productId = form.product[form.product.options.selectedIndex].value;
        let repId = form.rep[form.rep.options.selectedIndex].value;
        
        let quantity = parseInt(form.quantity.value);
        let invoice_no = form.invoice.options[form.invoice.options.selectedIndex].value;
        let inv =  storedData.inventory.filter(inv=>{
            return inv.invoice_no == invoice_no && inv.product.id == productId;
        });
        let amount = inv[0].selling_price * quantity;
        let price = amount / quantity;
        let transaction = {rep:repId,price:price,product:productId,invoice_no:invoice_no,quantity:quantity,amount:amount,admin:loginState.id};
        saveIssue(transaction);
    }
    const saveIssue=(issue)=>{
        var formData = new FormData();
        formData.append("product",issue.product);
        formData.append("rep",issue.rep);
        formData.append("invoice_no",issue.invoice_no);
        formData.append("quantity",issue.quantity);
        formData.append("price",issue.price);
        formData.append("amount",issue.amount);
        formData.append("admin",issue.admin);
        formData.append("btnIssueInventory","IssueInventory");
    
        
        var options = {
            method:"POST",
            body:formData,
            
        }
        fetch(UTILS.URL+"?tag=issues",options)
        .then(res=>res.json())
        .then(result=>{
            if(parseInt(result.code) === 0){
              let d = storedData;
                d.issues = result.issues;
                navigate("/inventory",{replace:true,state:d});
            }
            else setFeedback(result.code,result.msg);
            
        })
        .catch(err=>{
            console.log("err: ",err);
            setFeedback("Something went wrong! Please try again later");
        })
        .finally(()=>{
          setIsLoading(false);
        })
    }
    const handleRepChange=(e)=>{
      let val = e.target.value;
      setRep(val);
      if(val == 0 || prod == 0) setShowSubmit(false);
      else setShowSubmit(true);
    }
    const populate=(pid)=>{
      
      var invs = inventories.filter(iv=>iv.product.id === pid).map(iv=>iv.invoice_no);
        let def = inventories.find(iv=>iv.product.id == pid && iv.invoice_no == invs[0]);
        setInvoices(invs);
        setProd(pid);
        setInv(invs.length > 0 ?invs[0]:"");
        setQtty(def?def.quantity:0);
        setMax(def?def.quantity:0);
        if(pid == 0 || rep == 0){
          setShowSubmit(false);
        }
        else setShowSubmit(true);
    }
    const handleProductChange=(e)=>{
        let pid = e.target.value;
        populate(pid);
        if(pid == 0 || rep == 0) setShowSubmit(false);
      else setShowSubmit(true);
    }

    const handleInvoiceChange=e=>{
        let inv_no = e.target.value;
        let inv = inventories.find(iv=>iv.invoice_no === inv_no && iv.product.id === prod);
        setQtty(inv ? inv.quantity:0);
        setInv(inv_no);
    }
    const handleQttyChange=e=>{
      let q = (e.target.value) ? parseInt(e.target.value):0;
      if(q > max){
        // e.target.setCustomValidity("There is no enough inventory");
        e.target.focus();
      }
      else setQtty(q);
    }
   
    useEffect(()=>{
      populate(prod);
    },[prod])
    if(loginState){
        return (<>
        <Header auth={loginState}/>
        <section className="dashboard">
          {feedback.length > 0 ? <div className="feedback danger">{feedback}</div>:null}
    <div className="content">
      
      <div className="col-md-10 col-lg-10 col-sm-10 offset-1">
        <span className="medium-text">Issue Stock</span>
        <form id="issue_form" onSubmit={handleSubmit}
          className="col-md-10 col-lg-10 col-sm-10 offset-1"
          method="post"
        >
        <div className="row">
        <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
          <label htmlFor="rep">Select Rep</label>
          <select onChange={(e)=>handleRepChange(e)} value={rep}
            type="text"
            className="form-control"
            id="rep"
            name="rep">
              <option value={0}>--select--</option>
                {reps.map((r=>{
                    return <option key={UTILS.randomKey()} value={r.id}>{r.fname+" "+r.lname}</option>
                }))}
            </select>
         
        </div>
        <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
            <label htmlFor="product">Select Product</label>
            <select value={prod} onChange={(e)=>handleProductChange(e)}
              className="form-control"
              id="product"
              name="product">
                <option value={0}>--select--</option>
                {products.map(p=>{
                    return <option key={UTILS.randomKey()} value={p.id}>{p.name+" ("+p.pack_size+")"}</option>
                })}
            </select>
          </div>
         
        </div> 
        <div className="row">
          <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
            <label htmlFor="invoice">Select Invoice Number</label>
            <select
              className="form-control" onChange={(e)=>handleInvoiceChange(e)} value={inv}
              id="invoice"
              name="invoice">
                {invoices.map(i=>{
                    return <option key={UTILS.randomKey()}>{i}</option>
                })}
              </select>
          </div>
          <div className="form-group my-2 col-md-6 col-lg-6 col-sm-10">
            <label htmlFor="quantity">Quantity</label>
            <input value={qtty} onChange={(e)=>handleQttyChange(e)} max={qtty}
              type="number"
              className="form-control"
              id="quantity"
              name="quantity"
              placeholder="Number of cartons"
            />
          </div>
           
          </div>

         <div className="form-group my-2 row">
          {showSubmit ?
          <div className="my-2 col-md-6 col-lg-6 col-sm-10">
            {isLoading ? <Loading />:
            <input
              type="submit"
              className="form-control bg-success"
              id="btnSubmit"
              name="btnSubmit"
              value="SUBMIT"
            />}
          </div>:null}
          <div className="my-2 col-md-6 col-lg-6 col-sm-10">
            <input onClick={()=>{navigate(-1)}}
              type="button"
              className="form-control"
              value="Cancel"
            />
          </div>
        </div>
        </form>
      </div>
    </div>
    </section>
        </>)
    }
}
export default IssueInventory;